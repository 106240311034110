










































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import gsap from 'gsap'

export default defineComponent({
  name: 'GVideoPopup',
  setup(_props, ctx) {
    const { $router } = ctx.root
    const { videoId } = useGetters('ui', ['videoId'])
    const { UNSET_VIDEO: unsetVideo } = useMutations('ui', ['UNSET_VIDEO'])
    const popup = ref<HTMLElement | null>(null)
    const video = ref<HTMLElement | null>(null)
    const player = ref()

    const close = () => {
      const tl = gsap.timeline({
        onComplete: () => {
          unsetVideo()
        },
      })

      tl.to(popup.value!.querySelector('.video-popup__content'), {
        opacity: 0,
        duration: 0.25,
      })

      tl.to(popup.value, {
        scaleY: 0,
        duration: 0.5,
        ease: 'power4.out',
      })
    }

    const play = () => {
      player.value.play()
    }

    const pause = () => {
      player.value.pause()
    }

    onMounted(() => {
      popup.value!.querySelector('button')?.focus()
      window.addEventListener('keyup', close)

      const video = popup.value!.querySelector('.video-popup__content')
      const buttons = popup.value!.querySelectorAll(
        '.video-popup__controls__button'
      )

      const tl = gsap.timeline({
        onComplete: () => {
          popup.value?.focus()
          player.value.play()
        },
      })

      tl.from(popup.value, {
        background: '#fcaf17',
        scaleY: 0,
        duration: 1,
        ease: 'power4.in',
      })
        .from(video, {
          clipPath: 'inset(50% 0% 50% 0%)',
          ease: 'power4.out',
        })
        .from(buttons, { opacity: 0, y: 20, stagger: 0.1 })

      // Close popup on page change
      $router.beforeEach((_to, _from, next) => {
        close()
        next()
      })
    })

    return {
      popup,
      video,
      player,
      close,
      videoId,
      play,
      pause,
    }
  },
})
