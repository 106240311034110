
































import { defineComponent } from '@vue/composition-api'
import { PCard } from '@/inc/types'

import UiPicture from '@/components/ui/Picture.vue'
import UiLoader from '@/components/ui/Loader.vue'

export default defineComponent({
  name: 'ProjectCard',
  components: {
    UiPicture,
    UiLoader,
  },
  props: {
    content: {
      type: Object as () => PCard,
      required: true,
    },
  },
  setup(props, ctx) {
    const onLoad = () => ctx.emit('img:load')

    const onClick = (e: PointerEvent) => {
      const project = { ...props.content }

      ctx.emit('card:click', { target: e.currentTarget, project })
    }

    return { onLoad, onClick }
  },
})
