
























































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ChromeFooterMenu',
  components: {},
  props: {},

  setup() {
    return {
      ...useGetters(['chrome']),
    }
  },
})
