import VueRouter from 'vue-router'
import { useGetters } from '@u3u/vue-hooks'

type CampaignSource =
  | 'facebook'
  | 'instagram'
  | 'google'
  | 'pinterest'
  | 'tiktok'

export interface CampaignLandingPage {
  endDate: number
  prefix: string
  priority?: number
  startDate?: number
  value: string
}

interface CampaignManagerState {
  lastClick: CampaignLandingPage | null
  log: CampaignLandingPage[]
  sources: Partial<Record<CampaignSource, string>>
  utm: string | null
  utms: Record<string, string>
}

const cookieName = 'campaign'

export const campaignManager = (() => {
  const state: CampaignManagerState = {
    lastClick: null,
    log: [],
    utm: null,
    utms: {},
    sources: {},
  }
  let consent = false
  let cookieManager: any
  let router: VueRouter

  /*
   ** Init campaign manager with app cookie manager and router
   */
  const init = (appCookie: any, appRouter: VueRouter) => {
    cookieManager = appCookie
    router = appRouter
    initStateLegacy()
    initState()

    console.log('[init] state', { ...state })

    // Update state at init, and at key points during the session
    // + set UTM at start and end of session
    setUTM()
    update()

    // 1. When the user changes their cookies
    window.addEventListener('consent.onetrust', () => update)

    // 2. Before the user leaves the website
    window.addEventListener('beforeunload', () => {
      setUTM()
      update()
    })

    // 3. During navigation
    router.beforeEach((_to, _from, next: () => void) => {
      update()
      next()
    })
  }

  /*
   ** Init state based on cookie used before refacto
   ** Last click was saved in 'CAMPAIGN' cookie
   ** UTMs were saved in 'utm' cookie
   */
  const initStateLegacy = () => {
    // Update UTMs
    const utmCookie = cookieManager.get('utm')

    if (utmCookie) {
      state.utm = utmCookie

      // Add utms to state
      const utmParts = utmCookie.split('&')

      utmParts.forEach((part: string) => {
        const [key, value] = part.split('=')

        state.utms[key] = value
      })

      // Delete legacy cookie
      cookieManager.delete('utm')
    }

    // Update last click
    const lastClickCookie = cookieManager.get('CAMPAIGN')

    if (lastClickCookie) {
      const now = new Date()
      const startDate = now.getTime()
      const endDate = now.setMonth(now.getMonth() + 6)
      const [prefix] = lastClickCookie.split(' ')

      state.lastClick = {
        endDate,
        prefix,
        priority: 0,
        startDate,
        value: lastClickCookie.replace(`${prefix} `, ''),
      }

      // Delete legacy cookie
      cookieManager.delete('CAMPAIGN')
    }
  }

  /*
   ** Init state with info saved in cookie
   */
  const initState = () => {
    try {
      const cookieValue = JSON.parse(cookieManager.get(cookieName))

      // Check if the last click is expired
      if (Date.now() > cookieValue.lastClick?.endDate) {
        cookieValue.lastClick = null
      }

      // Add values into state
      Object.assign(state, cookieValue)
    } catch (e) {
      console.log('[initState] Cookie value not valid', e)
    }
  }

  /*
   ** Check if the use has consented to the marketing cookies
   */
  const getConsent = (): boolean => {
    // Cookiebot adds a CookieConsent cookie with all info regarding consent
    const cookie = cookieManager.get('CookieConsent')

    if (cookie) {
      // Cookie value is something like: `{key:'value',foo:'bar'}`
      // To read the properties, we convert it to a valid JSON string
      // https://stackoverflow.com/a/26291352
      const cookieJSON = cookie
        // wrap keys without quote with valid double quote
        .replace(/([$\w]+)\s*:/g, (_: string, $1: string) => `"${$1}":`)
        // replacing single quote wrapped ones to double quote
        .replace(/'([^']+)'/g, (_: string, $1: string) => `"${$1}"`)

      try {
        const { marketing } = JSON.parse(cookieJSON)

        consent = marketing || false
        console.log('[getCookieConsent] Based on marketing value:', marketing)
      } catch (e) {
        // cookieJSON is not valid JSON, meaning the value might have been corrupted
        // consent stays false
        consent = false
        console.log('[getCookieConsent] Value not valid')
      }
    } else {
      // If the cookie does not exist on the browser, track marketing cookies
      // (the user has not accepted nor refused the cookies
      // and has likely not encountered a cookie bar)
      consent = true
      console.log('[getCookieConsent] No CookieConsent cookie')
    }

    return consent
  }

  /*
   ** Update state with new info
   */
  const update = (campaigns?: CampaignLandingPage[]) => {
    // Make sure the user has consented to the marketing cookies
    if (!getConsent()) {
      return
    }

    if (campaigns?.length) {
      setAttribution(campaigns)
    } else {
      const { content } = useGetters(['content'])
      const { campaigns: pageCampaigns } = content.value

      setAttribution(pageCampaigns)
    }

    updateCookie()
    console.log('[campaignManager] update', state)
  }

  /*
   ** Helper function to set cookie
   ** The cookie contains the last attribution, last utm and last sources
   */
  const updateCookie = () => {
    // Set value
    const cookieValue: Partial<CampaignManagerState> = {
      sources: state.sources,
      utms: state.utms,
    }

    // Add lastClick
    if (state.lastClick && state.lastClick.value) {
      cookieValue.lastClick = {
        endDate: state.lastClick.endDate,
        prefix: state.lastClick.prefix,
        value: state.lastClick.value,
      }
    }

    // Set expiration date
    const expires = new Date()
    expires.setMonth(expires.getMonth() + 3)

    cookieManager.set(cookieName, JSON.stringify(cookieValue), {
      expires,
      path: '/',
    })
  }

  /*
   ** Get utm parameters from url
   ** eg: ?utm_source=instagram
   */
  const setUTM = () => {
    // Make sure the user has consented to the marketing cookies
    if (!getConsent()) {
      return
    }

    const { currentRoute } = router
    const { query } = currentRoute

    if (
      !query ||
      (!currentRoute.fullPath.includes('utm_') &&
        !currentRoute.fullPath.includes('gclid'))
    ) {
      return
    }

    // Reinitialize saved utms
    state.utms = {}
    state.utm = ''

    // Add every utm param to state
    for (const [key, value] of Object.entries(query)) {
      // if value is array, save last value
      const utmValue =
        Array.isArray(value) && value.length >= 1
          ? value[value.length - 1]
          : value

      state.utms[key] = utmValue as string
      state.utm += `${key}=${utmValue}&`
    }

    // Save current campaign source
    if (query.utm_campaign && (query.utm_source || query.gclid)) {
      const source = query.utm_source
        ? (query.utm_source as string).toLowerCase()
        : 'google'

      state.sources[source as CampaignSource] = query.utm_campaign.toString()
    }
  }

  /*
   ** Check if current page is a campaign landing page
   ** and if yes, add its value to the state
   */
  const setAttribution = (campaigns: CampaignLandingPage[]) => {
    if (!campaigns?.length) {
      console.log('[campaignManager] No campaigns')

      return
    }

    // By default, attribution is first campaign
    let [campaign] = campaigns

    // If landing page has multiple campaigns, find most relevant:
    // 1. same campaign (either source click, or last campaign seen)
    // 2. highest priority -> cookie stays the first item
    if (campaigns.length > 1) {
      if (state.log.length > 0) {
        // The user has seen some campaign pages
        // The relevant cookie is related to last campaign seen
        const currentCampaign = state.log[state.log.length - 1]
        const { prefix } = currentCampaign

        campaign =
          campaigns.find((c: CampaignLandingPage) => c.prefix === prefix) ||
          campaigns[0]
      } else if (Object.keys(state.utms).length) {
        // This is the first page the user sees
        // Try to associate page campaigns with utm_campaign
        const sourceCampaign = state.utms.utm_campaign

        if (sourceCampaign) {
          campaign =
            campaigns.find(
              (c: CampaignLandingPage) => c.prefix === sourceCampaign
            ) || campaigns[0]
        }
      }
    }

    // Save campaign in state
    state.lastClick = campaign
    campaign && state.log.push(campaign)
  }

  /*
   ** Access state property
   */
  const get = (property: string) => state[property]

  /*
   ** Get formatted data to send to Hubspot
   */
  const getFormData = (
    type:
      | 'quote_request'
      | 'newsletter'
      | 'brochure'
      | 'emagazine'
      | 'moodboard'
  ) => {
    /* eslint-disable camelcase */
    const data = {
      ...state.utms,
      camber_log: state.log.map(item => item.value).join(', '),
    }
    /* eslint-enable camelcase */

    // Add sources (format: last_foo_utm_campaign)
    Object.keys(state.sources).forEach(source => {
      const key = `last_${source}_utm_campaign`
      data[key] = state.sources[source]
    })

    // Add last click (format: type_last_click)
    if (state.lastClick) {
      const key = `${type}_last_click`
      data[key] = state.lastClick.value
    }

    return data
  }

  return {
    init,
    update,
    get,
    getFormData,
    state,
  }
})()
