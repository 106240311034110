import { render, staticRenderFns } from "./Pill.vue?vue&type=template&id=1afa5385&scoped=true"
import script from "./Pill.vue?vue&type=script&lang=ts"
export * from "./Pill.vue?vue&type=script&lang=ts"
import style0 from "./Pill.vue?vue&type=style&index=0&id=1afa5385&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1afa5385",
  null
  
)

export default component.exports