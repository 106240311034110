




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'UiLoader',
  props: {},
  setup() {
    return {}
  },
})
