import Vue from 'vue'
import VueRouter from 'vue-router'
import { Store } from 'vuex'

import { routes } from './routes'
import { guards } from './guards'
import { RootState } from '@/inc/store'

Vue.use(VueRouter)

export default function createRouter(store: Store<RootState>): VueRouter {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, _from, savedPosition) {
      // Full example: https://github.com/vuejs/vue-router/blob/dev/examples/scroll-behavior/app.js
      // Can return:
      // - "falsy" (null | void | {})
      // - { x: number, y: number }
      // - { selector: string, offset?: { x: number, y: number } }
      // - Promise (e.g. waiting for event/transition)

      if (savedPosition) {
        // SavedPosition is only available for popstate navigations.
        return savedPosition
      }

      // Manage scroll to anchor
      if (to.hash) {
        if (document.querySelector(to.hash)) {
          return {
            selector: to.hash as string,
          }
        }

        return null
      }

      // Check if any matched route config has meta that requires scrolling to top
      // Coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.matched.some(m => m.meta.scrollTop)) {
        return new Promise(resolve => {
          router.app.$root.$once('transition:middle', () => {
            resolve({
              x: 0,
              y: 0,
              behavior: 'instant' as ScrollBehavior,
            })
          })
        })
      }

      return null
    },
  })

  guards(router, store)

  return router
}
