













































































































import type { Input, Link, Picture } from '@/inc/types'

import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, ref } from '@vue/composition-api'
import { push, campaignManager, GtmLayer } from '@/inc/utils'
import { getApiUrl } from '@/inc/app.config'

import FormInput from '@/components/form/Input.vue'
import FormCheckbox from '@/components/form/Checkbox.vue'
import FormFeedback from '@/components/form/Feedback.vue'
import UiPicture from '@/components/ui/Picture.vue'

import { ValidationObserver } from 'vee-validate'
import axios from 'axios'

export interface FooterOverlay {
  form: {
    action: string
    subtitle?: string
    confirmation: string
    type: 'newsletter' | 'download' | 'stories'
    title: string
    input: Input[]
    submit: Link
  }
  side: {
    picture: Picture
    title: string
    checklist?: string[]
  }
  result: {
    title: string
    htmltext: string
    label: string
    link?: Link
  }
  location: 'footer' | 'content'
}

export default defineComponent({
  name: 'ChromeFooterOverlay',
  components: {
    FormInput,
    FormCheckbox,
    FormFeedback,
    ValidationObserver,
    UiPicture,
  },
  props: {
    popup: {
      type: Object as () => FooterOverlay,
      required: true,
    },
  },
  setup(props, ctx) {
    const form = ref<HTMLFormElement | null>(null)
    const sending = ref(false)
    const success = ref(false)
    const fail = ref(false)
    const { type } = props.popup.form

    const close = () => {
      ctx.emit('close')
    }

    const onSubmit = async () => {
      pushDatalayer()
      sending.value = true

      const { content } = useGetters(['content'])
      const formData = new FormData(form.value!)
      const action = `${getApiUrl()}/forms/${type}`
      const data = {
        action,
        location: process.env.VUE_APP_DOMAIN,
        // Hubspot data
        hutk: ctx.root.$cookie.get('hubspotutk'),
        pageUri: window.location.href,
        pageName: content.value.title,
        // Campaign data
        ...campaignManager.getFormData(
          type as 'newsletter' | 'brochure' | 'emagazine'
        ),
      }

      for (const pair of formData.entries()) {
        const [name, value] = pair
        if (typeof name === 'string') {
          data[name] = value
        }
      }

      try {
        const res = await axios.post(action, data)

        console.log('RES', res)
        success.value = true
        sending.value = false
      } catch (error) {
        console.error('form:error', error)
        fail.value = true
        sending.value = false
      }
    }

    // Push event to datalayer
    const pushDatalayer = () => {
      const { type } = props.popup.form
      const events = {
        download: 'download_brochure',
        newsletter: 'subscribe_newsletter',
      }
      const layer: GtmLayer = {
        event: events[type],
        ctaLocation: props.popup.location,
        // Add campaign params
        ...campaignManager.getFormData(
          type as 'newsletter' | 'brochure' | 'emagazine'
        ),
      }

      push(layer)
    }

    return {
      sending,
      success,
      fail,
      close,
      form,
      onSubmit,
      pushDatalayer,
      ...useGetters(['chrome']),
    }
  },
})
