

















































import type { Picture } from '@/inc/types'

import {
  defineComponent,
  ref,
  PropType,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
import { throttle } from 'throttle-debounce'
import { v4 as uuidv4 } from 'uuid'

import { push, GtmLayer } from '@/inc/utils'

interface VideoLayer extends GtmLayer {
  event: 'video'
  interactionType?: 'Play' | 'Pause'
  itemValue: string // Video Title
  linkUrl: string // Video url
  progress: string // Progress in %
  videoType: 'Podcast' | 'Video'
}

export default defineComponent({
  name: 'GVideo',
  components: {},
  props: {
    youtubeId: {
      type: String,
      required: true,
      default: 'BIT0NjPDmOQ',
    },
    // Loop
    loop: {
      type: String || (Object as PropType<{ url: string }>),
      required: false,
      default: undefined,
    },
    playLoop: {
      type: Boolean,
      required: false,
      default: true,
    },
    poster: {
      type: Object as PropType<Picture>,
      required: false,
    },
    showPosterOnPause: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: false,
    },
    options: {
      type: Object as PropType<Record<string, string | boolean>>,
      required: false,
      default: () => ({}),
    },
  },

  setup(props, ctx) {
    // Variables
    const uuid = uuidv4().replace(/(-).+/, '')
    let Vlitejs
    let currentStamp = 0

    // Refs
    const isPlayingRef = ref(false)
    const playerElRef = ref()
    const playerRef = ref()
    const { $route } = ctx.root

    // Methods
    const initPlayer = () => {
      const options = {
        bigPlay: false,
        providerParams: {
          modestbranding: 1,
          showinfo: 0,
        },
        ...props.options,
      }

      // eslint-disable-next-line no-new
      new Vlitejs(playerElRef.value, {
        options,
        provider: 'youtube',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onReady: (player: any) => {
          playerRef.value = player

          setTracking()
        },
      })
    }

    // Play video
    const play = (timestamp?: number) => {
      if (playerRef.value && !isPlayingRef.value) {
        // Set player time to given timestamp
        if (timestamp && typeof timestamp === 'number') {
          playerRef.value.seekTo(timestamp)
        }

        playerRef.value.play()
        playerRef.value.unMute()
        isPlayingRef.value = true
      }
    }

    // Pause video
    const pause = () => {
      if (playerRef.value && isPlayingRef.value) {
        playerRef.value.pause()
        isPlayingRef.value = false
      }
    }

    // Get current time, in s
    const getCurrentTime = (): number =>
      playerRef.value.instance.getCurrentTime()

    // Push gtm layer
    const pushLayer = ({
      stamp,
      interaction,
    }: {
      stamp?: number
      interaction?: 'Play' | 'Pause'
    }) => {
      if (!playerRef.value) {
        return
      }

      const layer: VideoLayer = {
        event: 'video',
        itemValue: playerRef.value.instance.videoTitle,
        linkUrl: `https://youtu.be/${props.youtubeId}`,
        pageLocation: window.location.href,
        progress: `${stamp || currentStamp}%`,
        videoType: $route.name === 'PodcastSingle' ? 'Podcast' : 'Video',
      }

      if (interaction) {
        layer.interactionType = interaction
      }

      push(layer)
    }

    // Add tracking on events
    const setTracking = () => {
      playerRef.value.on('timeupdate', onTimeChange)

      playerRef.value.on('play', () => {
        ctx.emit('play', getCurrentTime())
        pushLayer({ interaction: 'Play' })

        onPlay()
      })

      playerRef.value.on('pause', () => {
        ctx.emit('pause', getCurrentTime())
        pushLayer({ interaction: 'Pause' })

        onPause()
      })
      console.log(playerRef.value)
      playerRef.value.on('ended', () => {
        isPlayingRef.value = false
        pushLayer({ stamp: 100 })
      })
    }

    // Emits progress percentage
    const onTimeChange = throttle(150, () => {
      if (!isPlayingRef.value) {
        return
      }

      const duration = playerRef.value.instance.getDuration()
      const currentTime = getCurrentTime()
      const percentage = (currentTime / duration) * 100

      ctx.emit('progress', percentage)
      trackTimeChange(percentage)
    })

    // Sends event when a specific time has passed
    // values: 25%, 50%, 75%
    const trackTimeChange = throttle(2000, (percentage: number) => {
      if (!isPlayingRef.value) {
        return
      }

      let stamp = 0

      if (percentage > 25) {
        if (percentage > 50) {
          if (percentage > 75) {
            stamp = 75
          } else {
            stamp = 50
          }
        } else {
          stamp = 25
        }
      } else {
        stamp = 0
      }

      if (stamp !== currentStamp) {
        currentStamp = stamp
        pushLayer({})
      }
    })

    // On video pause
    // vlite event
    const onPause = () => {
      if (props.showPosterOnPause) {
        isPlayingRef.value = false
      }
    }

    // On video pause
    // vlite event
    const onPlay = () => (isPlayingRef.value = true)

    onMounted(async () => {
      if (!props.youtubeId) {
        return
      }

      Vlitejs = await import(
        /* webpackChunkName: 'vendor-vlitejs' */ 'vlitejs'
      ).then(module => module.default)

      initPlayer()
    })

    onBeforeUnmount(() => {
      playerRef.value && playerRef.value.destroy()
    })

    return {
      uuid,
      isPlayingRef,
      playerElRef,
      playerRef,
      play,
      pause,
      getCurrentTime,
    }
  },
})
