








import { defineComponent } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'

import gsap from 'gsap'

export default defineComponent({
  name: 'PageTransition',
  components: {},
  props: {},

  setup(_props, ctx) {
    const { isMenuOpen } = useGetters('ui', ['isMenuOpen'])
    const { CLOSE_MENU: closeMenu } = useMutations('ui', ['CLOSE_MENU'])
    let navigatedFromMenu = false

    const onLeave = (el: Element, done: () => void) => {
      ctx.root.$emit('transition:start')
      navigatedFromMenu = isMenuOpen.value

      if (isMenuOpen.value) {
        done()
      }

      const footer = document.querySelector('footer.footer')

      gsap.to([el, footer], {
        opacity: 0,
        duration: 0.2,
        ease: 'power4.in',
        onComplete: () => {
          ctx.root.$emit('transition:middle')
          done()
        },
      })
    }

    const onEnter = (el: Element, done: () => void) => {
      const header = document.querySelector('header.header')
      const footer = document.querySelector('footer.footer')
      const tween = gsap.fromTo(
        footer ? [el, footer] : el,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.5,
          ease: 'power4.out',
          paused: true,
          onComplete: () => {
            ctx.root.$emit('transition:end')
            ctx.root.$emit('page:ready')
            done()
          },
        }
      )

      // If the transition has been triggered by the menu
      // AND the menu is still on the page, wait for menu close
      if (header && navigatedFromMenu) {
        closeMenu()

        ctx.root.$once('menu:closed', () => {
          tween.play()
        })
      } else {
        tween.play()
      }
    }

    return {
      onLeave,
      onEnter,
    }
  },
})
