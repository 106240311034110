







import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'FormFeedback',
  props: {
    content: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: false,
    },
  },
  setup() {
    return {}
  },
})
