// Return new point on the line defined by points a and b at p progress
function getPointAt(
  a: { x: number; y: number },
  b: { x: number; y: number },
  p: number
) {
  /* eslint-disable no-mixed-operators */
  return {
    x: a.x + (b.x - a.x) * p,
    y: a.y + (b.y - a.y) * p,
  }
  /* eslint-enable no-mixed-operators */
}

// Return distance between points a and b
function getDistance(a: { x: number; y: number }, b: { x: number; y: number }) {
  return Math.hypot(b.x - a.x, b.y - a.y)
}

export { getPointAt, getDistance }
