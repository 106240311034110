import { FastAverageColor } from 'fast-average-color'
import type { Material } from '@/inc/types'

const fac = new FastAverageColor()

function adjustBrightness(col: string, amt: number) {
  const num = parseInt(col.charAt(0) === '#' ? col.slice(1) : col, 16)

  // eslint-disable-next-line no-nested-ternary
  const clamp = (val: number) => (val < 0 ? 0 : val > 255 ? 255 : val)

  return (
    (col.charAt(0) === '#' ? '#' : '') +
    [0, 8, 16]
      .map(shift => clamp(((num >> shift) & 0xff) + amt) << shift)
      .reduce((a, c) => a + c, 0)
      .toString(16)
      .padStart(6, '0')
  )
}

async function getAverageColorHex(src: string) {
  const color = await fac.getColorAsync(src)

  return color.hex
}

function getBrightness(hex: string) {
  // Format #ffffff / #fff
  // https://www.w3.org/TR/WCAG20/#relativeluminancedef
  // Y = 0.2126R + 0.7152G + 0.0722B
  const parsed = hex.substr(1).match(hex.length === 7 ? /(\S{2})/g : /(\S{1})/g)

  if (!parsed) {
    return 0
  }

  // sRGB normalized
  const RsRGB = parseInt(parsed[0], 16) / 255
  const GsRGB = parseInt(parsed[1], 16) / 255
  const BsRGB = parseInt(parsed[2], 16) / 255

  const R =
    RsRGB <= 0.03928 ? RsRGB / 12.92 : Math.pow((RsRGB + 0.055) / 1.055, 2.4)
  const G =
    GsRGB <= 0.03928 ? GsRGB / 12.92 : Math.pow((GsRGB + 0.055) / 1.055, 2.4)
  const B =
    BsRGB <= 0.03928 ? BsRGB / 12.92 : Math.pow((BsRGB + 0.055) / 1.055, 2.4)

  // For the sRGB colorspace, the relative luminance of a color is defined as:
  // eslint-disable-next-line no-mixed-operators
  const L = 0.2126 * R + 0.7152 * G + 0.0722 * B

  return L
}

// ?: should probably go in another module…
function setMaterialAverageColor(mat: Material) {
  if (mat.color) {
    mat.colorAverage = Promise.resolve(mat.color)
  } else if (mat.picture) {
    mat.colorAverage = getAverageColorHex(mat.picture.src)
  }
}

export { adjustBrightness, setMaterialAverageColor, getBrightness }
