


















































































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent, ref, onMounted } from '@vue/composition-api'

import { cache, push } from '@/inc/utils'
import { getApiUrl } from '@/inc/app.config'
import { PCard, PCategory, Picture } from '@/inc/types'
import { ResourceSingle } from '@/inc/store/modules/resource'

import UiPicture from '@/components/ui/Picture.vue'
import ProjectCard from '@/components/project/Card.vue'

export default defineComponent({
  name: 'ProjectGrid',
  components: {
    UiPicture,
    ProjectCard,
  },
  props: {
    pictures: {
      type: Array as () => Picture[],
      required: false,
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array as () => string[],
      required: false,
      default: () => [],
    },
    category: {
      type: Object as () => PCategory,
      required: false,
    },
    subcategory: {
      type: Object as () => PCategory,
      required: false,
    },
  },
  setup(props, ctx) {
    const { content } = useGetters(['content'])

    const grid = ref<HTMLElement | null>(null)
    const projects = ref<PCard[] | Picture[]>(
      content.value.realizations ? content.value.realizations.content : []
    )
    const numberToDisplay = ref(30)
    const totalPosts = ref(
      content.value.realizations ? content.value.realizations.total_posts : 0
    )

    let currentPage = 1
    let masonry

    const isPicture = (item: Picture | PCard): item is Picture =>
      Boolean((item as Picture).src)

    const refreshLayout = (refreshAll = false) => {
      if (masonry) {
        masonry.reloadItems()

        // Safari needs timeout otherwise the layout is not performed
        // #332 was fixed but not on Safari
        setTimeout(
          () => {
            masonry.layout()
            grid.value!.classList.remove('is-loading')
          },
          refreshAll ? 500 : 0
        )
      }
    }

    const loadMore = async () => {
      numberToDisplay.value += 12

      if (
        projects.value.length <= numberToDisplay.value &&
        projects.value.length < totalPosts.value
      ) {
        currentPage += 1

        await fetchProjects()
      }

      setTimeout(() => {
        refreshLayout()
      }, 200)

      // Emit to track
      ctx.emit('loadmore')
    }

    const fetchProjects = async (replaceProjects = false) => {
      let url = `${getApiUrl()}/realizations?page=${currentPage}`

      if (props.category) {
        url += `&category=${props.category.value}`

        if (props.subcategory) {
          url += `&category=${props.subcategory.value}`
        }
      }

      const res = await cache.fetch(ctx.root.$route.fullPath, url)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data = (res.data as ResourceSingle).content!.realizations

      projects.value = replaceProjects
        ? data.content
        : [...projects.value, ...data.content]
      totalPosts.value = data.total_posts

      console.log('fetched posts:', projects.value.length)
      console.log('total posts:', totalPosts.value)
    }

    // Handle click on project card
    const onProjectClick = ({
      target,
      project,
    }: {
      target?: HTMLElement
      project: PCard
    }) => {
      // Emit to parent to open popup + track number of clicks
      ctx.emit('project:click', { target, project })

      // Push datalayer event
      push({
        event: 'realisation_clicked',
        /* eslint-disable camelcase, id-length */
        realisation_name: project.title,
        realisation_category_name: props.category?.label || 'all',
        realisation_subcategory_name: props.subcategory?.label,
        /* eslint-enable camelcase, id-length */
      })
    }

    const onCategoryChange = () => {
      grid.value!.classList.add('is-loading')

      setTimeout(async () => {
        numberToDisplay.value = 30
        currentPage = 1

        await fetchProjects(true)

        refreshLayout(true)
      }, 200)
    }

    // Track which cards have appeared
    const onCardAppear = () => ctx.emit('project:appear')

    onMounted(async () => {
      if (props.isForm) {
        await fetchProjects()
      } else if (props.pictures) {
        projects.value = props.pictures
      }

      const Masonry = await import(
        /* webpackChunkName: 'vendor-flickity' */ 'masonry-layout'
      ).then(module => module.default)

      masonry = new Masonry(grid.value!.querySelector('.masonry-grid'), {
        itemSelector: '.masonry-grid__item',
        columnWidth: '.grid-sizer',
        percentPosition: true,
        transitionDuration: 0, // TODO: Transition
      })

      grid.value!.classList.remove('is-loading')
    })

    return {
      grid,
      projects,
      numberToDisplay,
      totalPosts,
      isPicture,
      refreshLayout,
      loadMore,
      onCategoryChange,
      onProjectClick,
      fetchProjects,
      onCardAppear,
    }
  },
})
