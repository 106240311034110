











































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

const components = {
  assert: () => import('@/components/flexible/Assert.vue'),
  beforeAfter: () => import('@/components/flexible/BeforeAfter.vue'),
  blog: () => import('@/components/g/Wysiwyg.vue'),
  caseStudy: () => import('@/components/flexible/CaseStudy.vue'),
  conditions: () => import('@/components/flexible/Conditions.vue'),
  cta: () => import('@/components/flexible/Quote.vue'),
  ctaExperience: () => import('@/components/cta/Experience.vue'),
  ctaMood: () => import('@/components/cta/Mood.vue'),
  ctaMoodboard: () => import('@/components/cta/Moodboard.vue'),
  ctaPromo: () => import('@/components/flexible/Promo.vue'),
  ctaStories: () => import('@/components/cta/Stories.vue'),
  ctaText: () => import('@/components/cta/Text.vue'),
  ctaType: () => import('@/components/cta/PreEncoded.vue'),
  didYouKnow: () => import('@/components/flexible/DidYouKnow.vue'),
  duo: () => import('@/components/flexible/Duo.vue'),
  episodes: () => import('@/components/home-organizers/Episodes.vue'),
  experts: () => import('@/components/flexible/Experts.vue'),
  followUp: () => import('@/components/flexible/FollowUp.vue'),
  gallery: () => import('@/components/flexible/Gallery.vue'),
  galleryAdjectives: () => import('@/components/flexible/Gallery.vue'),
  galleryTrio: () => import('@/components/personality/Gallery.vue'),
  galleryWlink: () => import('@/components/flexible/Gallery.vue'),
  galleryWtext: () => import('@/components/flexible/Gallery.vue'),
  highlightDuo: () => import('@/components/flexible/HighlightDuo.vue'),
  highlighted: () => import('@/components/flexible/Highlighted.vue'),
  highlighted2: () => import('@/components/flexible/HighlightedSection.vue'),
  iconsList: () => import('@/components/flexible/IconList.vue'),
  keyFacts: () => import('@/components/flexible/KeyList.vue'),
  keyFigures: () => import('@/components/flexible/KeyList.vue'),
  linkedPosts: () => import('@/components/flexible/CardSlider.vue'), // slider of cards to posts, vertical
  links: () => import('@/components/flexible/Links.vue'), // list of wysiwygs with link icon
  materials: () => import('@/components/flexible/Materials.vue'),
  miniGallery: () => import('@/components/flexible/Gallery.vue'),
  moodboard: () => import('@/components/personality/Moodboard.vue'),
  moodSlider: () => import('@/components/anders-wonen/MoodSlider.vue'),
  picture: () => import('@/components/flexible/Picture.vue'),
  pictureLink: () => import('@/components/flexible/Picture.vue'),
  pictureTextDuo: () => import('@/components/flexible/DuoPicture.vue'),
  prepare: () => import('@/components/flexible/Prepare.vue'),
  priceDetails: () => import('@/components/flexible/PriceDetails.vue'),
  projectDetails: () => import('@/components/flexible/Slider.vue'),
  quote: () => import('@/components/flexible/Quote.vue'),
  // Soon:
  // quotes: () => import('@/components/flexible/Quote.vue'),
  video: () => import('@/components/flexible/Video.vue'),
  view360: () => import('@/components/personality/360.vue'),
  wysiwyg: () => import('@/components/g/Wysiwyg.vue'),
}

export default defineComponent({
  name: 'GFlexibleAsync',

  setup() {
    const { content } = useGetters(['content'])

    // Top and bottom border will be disabled on components preceeding and following those
    const disableBorderOnSiblings = ['priceDetails']

    // Returns true if flexibleContent[index] is included in disableBorderOnSiblings array
    const disableComponentBorder = (index: number) => {
      const siblingComponent = content.value.flexibleContent[index]

      if (siblingComponent) {
        return disableBorderOnSiblings.includes(siblingComponent.component)
      }

      return false
    }

    // Note: the  disableBorderXXX are hacks around the lack of :has css selector support.
    // Ideally we would use next sibling + has to select next and previous (:has) sibling and remove their borders.
    const disableBorderBottom = (index: number) =>
      disableComponentBorder(index + 1)
    const disableBorderTop = (index: number) =>
      disableComponentBorder(index - 1)

    return {
      components,
      content,
      disableBorderTop,
      disableBorderBottom,
    }
  },
})
