


















































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useGetters, useMutations } from '@u3u/vue-hooks'
import { pushCta, GtmCta } from '@/inc/utils'

import ChromeHeaderLang from '@/components/chrome/header/Lang.vue'
import ChromeHeaderTrigger from '@/components/chrome/header/Trigger.vue'
import ChromeHeaderMenu from '@/components/chrome/header/Menu.vue'

export default defineComponent({
  name: 'ChromeHeader',
  components: {
    ChromeHeaderLang,
    ChromeHeaderTrigger,
    ChromeHeaderMenu,
  },
  setup(_props, ctx) {
    const { chrome, template } = useGetters(['chrome', 'template'])
    const { isMenuOpen, headerColor } = useGetters('ui', [
      'isMenuOpen',
      'headerColor',
    ])
    const { TOGGLE_MENU: toggleMenu } = useMutations('ui', ['TOGGLE_MENU'])

    const rootRef = ref<HTMLElement | null>(null)
    const menuRef = ref<InstanceType<typeof ChromeHeaderMenu> | null>(null)
    const triggerRef = ref<InstanceType<typeof ChromeHeaderTrigger> | null>(
      null
    )

    const yPosition = ref(0)
    const goingUp = ref(false)

    const onScroll = (x: number, y: number) => {
      goingUp.value = y < yPosition.value
      yPosition.value = y
    }

    const onTriggerClick = () => {
      toggleMenu()
    }

    const onMenuEnter = async (el: HTMLElement, done: () => void) => {
      if (!menuRef.value) {
        done()
      }

      await menuRef.value?.open()
      ctx.root.$emit('menu:opened')
      done()
    }

    const onMenuLeave = async (el: HTMLElement, done: () => void) => {
      if (!menuRef.value) {
        done()
      }

      triggerRef.value?.close()
      await menuRef.value?.close()
      ctx.root.$emit('menu:closed')
      done()
    }

    // Push event to datalayer on click
    const pushDatalayer = () => {
      if (!rootRef.value) {
        return
      }

      const ctaLabel = (chrome.value.ctas.headerRdv.label ||
        chrome.value.ctas.headerRdv.title) as string

      const layer: Partial<GtmCta> = {
        ctaLabel,
        ctaType: 'header',
        ctaUrl: chrome.value.ctas.headerRdv.url,
      }

      pushCta(layer, rootRef.value)
    }

    onMounted(() => {
      onScroll(window.scrollX, window.scrollY)
    })

    return {
      rootRef,
      menuRef,
      triggerRef,
      chrome,
      template,
      isMenuOpen,
      headerColor,
      onScroll,
      pushDatalayer,
      yPosition,
      goingUp,
      onTriggerClick,
      onMenuEnter,
      onMenuLeave,
    }
  },
})
