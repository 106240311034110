









import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GChecklist',
  props: {
    content: {
      type: Array as () => string[],
      required: true,
    },
  },
})
