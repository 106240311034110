import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/inc/store'
import { FileRecord } from 'vue-file-agent'
import { File, SelectOption } from '@/inc/types'
import { StorageUtils } from '@/inc/plugins/storage'

export interface ShowroomData {
  title: string
  value: string
  zip: string | number
}

interface Categories {
  items: Array<
    SelectOption & {
      sfValue: string
    }
  >
  value: string
}

export interface ProjectData {
  category?: string
  categories?: Categories
  message?: string
  files?: FileRecord[] | File[]
  attachments?: string
  selectedRealisation?: string[]
  inspirations?: string
  moodboard?: string
}

export interface PersonalData {
  lastName?: string
  firstName?: string
  email?: string
  phone?: string
  country?: string
  address?: string
  zip?: string
  city?: string
  valid?: boolean
  // Partner
  accessCode?: string
  projectName?: string
}

export interface StepsState {
  current: number
  personal: PersonalData | null
  project: ProjectData | null
  showroom: ShowroomData | null
  status: Status
  message: string | null
}

type Status = 'idle' | 'submitting' | 'error'

const state: StepsState = {
  showroom: null,
  personal: null,
  project: null,
  current: 1,
  status: 'idle',
  message: null,
}

const getStep = (storage: StorageUtils, step: string) => {
  const savedState = storage.getItem('steps', false)

  if (savedState) {
    try {
      const parsedState = JSON.parse(savedState)

      return parsedState[step]
    } catch (e) {
      console.error('[getStep] Invalid storage value', e)
    }
  }

  return null
}

const getters: GetterTree<StepsState, RootState> = {
  state: (state: StepsState) => state,
  message: (state: StepsState) => state.message,
  status: (state: StepsState) => state.status,
  showroom: (state: StepsState) => (storage: StorageUtils) =>
    state.showroom || getStep(storage, 'showroom'),
  personal: (state: StepsState) => (storage: StorageUtils) =>
    state.personal || getStep(storage, 'personal'),
  project: (state: StepsState) => (storage: StorageUtils) =>
    state.project || getStep(storage, 'project'),
  currentStep: (state: StepsState) => state.current,
}

const mutations: MutationTree<StepsState> = {
  SHOWROOM_ADD(state, payload: ShowroomData) {
    state.showroom = payload
  },
  SHOWROOM_RESET(state) {
    state.showroom = null
  },
  PERSONAL_ADD(state, payload: PersonalData) {
    state.personal = payload
  },
  PERSONAL_RESET(state) {
    state.personal = null
  },
  PROJECT_ADD(state, payload: ProjectData) {
    state.project = payload
  },
  PROJECT_RESET(state) {
    state.project = null
  },
  CURRENTSTEP_RESET(state) {
    state.current = 1
  },
  STATUS_UPDATE(state, payload: Status) {
    state.status = payload
  },
  MESSAGE_UPDATE(state, payload: string) {
    state.message = payload
  },
}

const actions: ActionTree<StepsState, RootState> = {
  initState({ state }, storage) {
    state.showroom = getStep(storage, 'showroom')
    state.personal = getStep(storage, 'personal')
    state.project = getStep(storage, 'project')
  },
  addShowroom({ commit, state }, { data, storage }) {
    commit('SHOWROOM_ADD', data)
    storage.setItem('steps', JSON.stringify(state))
  },
  addPersonal({ commit, state }, { data, storage }) {
    commit('PERSONAL_ADD', data)
    storage.setItem('steps', JSON.stringify(state))
  },
  addProject({ commit, state }, { data, storage }) {
    commit('PROJECT_ADD', data)
    storage.setItem('steps', JSON.stringify(state))
  },
  cleanSteps({ commit }, { storage }) {
    storage.removeItem('steps')
    commit('SHOWROOM_RESET')
    commit('PERSONAL_RESET')
    commit('PROJECT_RESET')
    commit('CURRENTSTEP_RESET')
  },
  updateStatus({ commit }, status: Status) {
    commit('STATUS_UPDATE', status)

    if (status === 'idle') {
      commit('MESSAGE_UPDATE', null)
    }
  },
  updateMessage({ commit }, message: string | null) {
    commit('MESSAGE_UPDATE', message)
  },
}

const steps: Module<StepsState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}

export default steps
