














































































































































import type { Picture } from '@/inc/types'
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import gsap from 'gsap'
import { push, GtmLayer } from '@/inc/utils'

import UiPicture from '@/components/ui/Picture.vue'

interface FlexibleBeforeAfter {
  title: string
  items: {
    pictureBefore: Picture
    pictureAfter: Picture
    htmltext?: string
  }[]
  labels: {
    before: string
    after: string
  }
}

export default defineComponent({
  name: 'FlexibleBeforeAfter',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<FlexibleBeforeAfter>,
      required: true,
    },
  },

  setup(props) {
    // HTML Elements
    const rootElRef = ref<HTMLElement | null>(null)
    const innerElRef = ref<HTMLElement | null>(null)
    const sliderElRef = ref<HTMLElement | null>(null)
    const itemsElRef = ref<HTMLElement[]>([])
    const buttonElRef = ref<HTMLElement | null>(null)
    const afterPicturesEl =
      rootElRef.value?.querySelectorAll(
        '.flexible-before-after__slider__item__picture--after'
      ) || []

    //  Refs
    const indexRef = ref(0)
    const isDraggingRef = ref(false)
    const progressRef = ref(10)
    const showButtonRef = ref(false)

    // Vars
    const breakpoint = 1024
    const measures = {
      padding: 0,
      left: 0,
    }

    // Update component measures to position elements later
    const setMeasures = () => {
      if (!innerElRef.value) {
        return
      }

      const innerElStyles = window.getComputedStyle(innerElRef.value)

      measures.padding = parseInt(
        innerElStyles.getPropertyValue('padding-left').replace('px', ''),
        10
      )
      measures.left = innerElRef.value.getBoundingClientRect().left
    }

    // Checks if picture is portrait or landscape
    const isPortrait = (picture: Picture) => picture.width < picture.height

    // Show button if we're under breakpoint or if picture is not portrait
    const shouldShowButton = () => {
      if (window.innerWidth < breakpoint) {
        return true
      }

      return !isPortrait(props.content.items[indexRef.value].pictureAfter)
    }

    // On nav item click, update index
    const onNavClick = (index: number) => {
      if (index === indexRef.value || !rootElRef.value) {
        return
      }

      const slider = rootElRef.value?.querySelector(
        '.flexible-before-after__slider'
      )
      const previousItem = itemsElRef.value[indexRef.value]
      const newItem = itemsElRef.value[index]
      const tl = gsap.timeline({
        onComplete: () => {
          console.log(
            slider,
            buttonElRef.value,
            ...afterPicturesEl,
            newItem,
            previousItem
          )
          gsap.set(
            [
              slider,
              buttonElRef.value,
              ...afterPicturesEl,
              newItem,
              previousItem,
            ],
            {
              clearProps: 'all',
            }
          )
        },
      })

      tl.set(newItem, { opacity: 0 })
        .to(previousItem, { opacity: 0, duration: 0.2 }, 0)
        .set([buttonElRef.value, ...afterPicturesEl], {
          clearProps: 'all',
        })
        .to(slider, { height: newItem.clientHeight, duration: 0.2 })
        .add(() => {
          progressRef.value = 50
          indexRef.value = index
          showButtonRef.value = shouldShowButton()
        })
        .to(newItem, { opacity: 1, duration: 0.2 })

      // Track click
      push({
        event: 'before_after',
        interactionType: 'Picture change',
      })
    }

    // On drag start, add all events
    const onDragStart = () => {
      if (!buttonElRef.value) {
        return
      }

      isDraggingRef.value = true
      window.addEventListener('blur', onDragEnd)
      window.addEventListener('mouseup', onDragEnd)
      window.addEventListener('mousemove', onDragMove)
      window.addEventListener('touchend', onDragEnd)
      window.addEventListener('touchcancel', onDragEnd)
      window.addEventListener('touchmove', onDragMove, { passive: false })
    }

    // On drag move, update position of button and clippath
    const onDragMove = (e: MouseEvent | TouchEvent) => {
      e.preventDefault()

      const item = itemsElRef.value[indexRef.value]

      if (!item) {
        return
      }

      let pageX: number

      if ((e as TouchEvent).changedTouches) {
        // eslint-disable-next-line prefer-destructuring
        pageX = (e as TouchEvent).changedTouches[0].pageX
      } else {
        // eslint-disable-next-line prefer-destructuring
        pageX = (e as MouseEvent).pageX
      }

      const relativePageX = pageX - measures.left - measures.padding

      progressRef.value = gsap.utils.clamp(
        0,
        100,
        (relativePageX / item.clientWidth) * 100
      )
    }

    // On drag end, remove all event listeners
    const onDragEnd = () => {
      isDraggingRef.value = false

      window.removeEventListener('blur', onDragEnd)
      window.removeEventListener('mouseup', onDragEnd)
      window.removeEventListener('mousemove', onDragMove)
      window.removeEventListener('touchend', onDragEnd)
      window.removeEventListener('touchcancel', onDragEnd)
      window.removeEventListener('touchmove', onDragMove)

      // Track action
      const layer: GtmLayer = {
        event: 'before_after',
        interactionType: 'Drag End',
        progress: Math.round(progressRef.value),
      }

      if (progressRef.value <= 25) {
        layer.seenAfter = 1
      } else if (progressRef.value >= 75) {
        layer.seenBefore = 1
      }

      // Push to datalayer
      push(layer)
    }

    // Listen to keyboard events on button focus
    const onButtonFocus = () => window.addEventListener('keyup', onKeyUp)

    // Remove event listener on button blur
    const onButtonBlur = () => window.removeEventListener('keyup', onKeyUp)

    // Move button and clippath based on key
    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') {
        // Move button and clippath to left
        progressRef.value = gsap.utils.clamp(0, 100, progressRef.value - 10)
      } else if (e.key === 'ArrowRight') {
        // Move button and clippath to right
        progressRef.value = gsap.utils.clamp(0, 100, progressRef.value + 10)
      }
    }

    // Update measures and check if button should be shown
    const onResize = () => {
      setMeasures()

      showButtonRef.value = shouldShowButton()

      // if current pic is portrait and over breakpoint
      if (!showButtonRef.value) {
        gsap.set([buttonElRef.value, ...afterPicturesEl], {
          clearProps: 'all',
        })
      }
    }

    // On appear, animate progress
    const onAppear = () => {
      gsap.fromTo(
        rootElRef.value,
        { '--slider-progress': '10%' },
        {
          /* eslint-disable quote-props */
          '--slider-progress': '50%',
          duration: 2,
          ease: 'back.out(3)',
          /* eslint-enable quote-props */
        }
      )
    }

    onMounted(() => {
      setMeasures()

      showButtonRef.value = shouldShowButton()
    })

    onUnmounted(() => {
      window.removeEventListener('blur', onDragEnd)
      window.removeEventListener('mouseup', onDragEnd)
      window.removeEventListener('mousemove', onDragMove)
      window.removeEventListener('touchend', onDragEnd)
      window.removeEventListener('touchcancel', onDragEnd)
      window.removeEventListener('touchmove', onDragMove)
      window.removeEventListener('keyup', onKeyUp)
    })

    return {
      rootElRef,
      innerElRef,
      sliderElRef,
      itemsElRef,
      buttonElRef,
      indexRef,
      showButtonRef,
      isDraggingRef,
      progressRef,
      isPortrait,
      onNavClick,
      onDragStart,
      onButtonFocus,
      onButtonBlur,
      onResize,
      onAppear,
    }
  },
})
