import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/inc/store'

type TransitionType = 'default'

export interface UiState {
  videoId: string | null
  isMenuOpen: boolean
  transitionType: TransitionType
  headerColor: 'white' | 'default'
}

const state: UiState = {
  videoId: null,
  isMenuOpen: false,
  transitionType: 'default',
  headerColor: 'default',
}

const getters: GetterTree<UiState, RootState> = {
  videoId: state => state.videoId,
  isMenuOpen: state => state.isMenuOpen,
  transitionType: state => state.transitionType,
  headerColor: state => state.headerColor,
}

const mutations: MutationTree<UiState> = {
  SET_VIDEO(state, payload: string) {
    state.videoId = payload
    document.body.classList.add('no-scroll')
  },
  UNSET_VIDEO(state) {
    state.videoId = null
    document.body.classList.remove('no-scroll')
  },
  OPEN_MENU(state) {
    state.isMenuOpen = true
    document.body.classList.add('no-scroll')
  },
  CLOSE_MENU(state) {
    state.isMenuOpen = false
    document.body.classList.remove('no-scroll')
  },
  TOGGLE_MENU(state) {
    if (state.isMenuOpen) {
      state.isMenuOpen = false
      document.body.classList.remove('no-scroll')
    } else {
      state.isMenuOpen = true
      document.body.classList.add('no-scroll')
    }
  },
  SET_TRANSITION_TYPE(state, payload: TransitionType) {
    state.transitionType = payload
  },
  SET_HEADER_COLOR(state, payload?: 'white' | 'default') {
    state.headerColor = payload || 'default'
  },
}

const actions: ActionTree<UiState, RootState> = {
  toggleMenu({ commit }) {
    commit('TOGGLE_MENU')
  },
  setHeaderColor({ commit }, color) {
    commit('SET_HEADER_COLOR', color)
  },
}

export const ui: Module<UiState, RootState> = {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
