










































import { defineComponent, computed } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { GLink } from '@/inc/types'

export default defineComponent({
  name: 'GCrumbs',
  props: {
    links: {
      type: Array as () => GLink[],
      default: () => [],
    },
  },
  setup(props, ctx) {
    const { meta, content } = useGetters(['meta', 'content'])

    const crumbs = computed(() =>
      props.links && props.links.length ? props.links : content.value.breadcrumb
    )

    const onClick = (e: PointerEvent) => {
      // if crumb link is the same url as the archive page, close popup

      const href =
        (e.target as HTMLAnchorElement).href ||
        ((e.target as HTMLElement).parentElement as HTMLAnchorElement).href

      if (props.links.length && href === window.location.href) {
        ctx.root.$ee.$emit('popup:close')
      }
    }

    return {
      meta,
      content,
      crumbs,
      onClick,
    }
  },
})
