var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.content.flexibleContent.length)?_c('div',{staticClass:"flexible"},_vm._l((_vm.content.flexibleContent),function(cpt,index){return _c(cpt.component && _vm.components[cpt.component]
        ? _vm.components[cpt.component]
        : 'pre',{key:("fl-" + index),tag:"component",class:[
      "cpt-" + cpt.component,
      {
        'no-border-top': _vm.disableBorderTop(index),
        'no-border-bottom': _vm.disableBorderBottom(index),
      } ],attrs:{"content":cpt.content}})}),1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }