






















































































import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { FormOverlay, PCategory, PCard } from '@/inc/types'

import FormSelect from '@/components/form/Select.vue'
import ProjectGrid from '@/components/project/Grid.vue'

export default defineComponent({
  name: 'ProjectsOverlay',
  components: {
    FormSelect,
    ProjectGrid,
  },
  props: {
    popup: {
      type: Object as () => FormOverlay,
      required: true,
    },
  },
  setup(props, ctx) {
    // TODO: récupérer la catégorie depuis le store
    const category = ref<PCategory | null>(null)
    const grid = ref<InstanceType<typeof ProjectGrid> | null>(null)
    const selectedProjects = ref<string[]>(props.popup.selection || [])
    const showModalRef = ref(false)
    const maxSelection = 10

    const setCategory = (cat: string) => {
      category.value = props.popup.categories.options.find(
        c => c.value === cat
      ) as PCategory

      if (grid.value) {
        grid.value.onCategoryChange()
      }
    }

    const updateSelection = ({ project }: { project: PCard }) => {
      const { url } = project

      if (selectedProjects.value!.includes(url)) {
        const i = selectedProjects.value!.indexOf(url)
        selectedProjects.value!.splice(i, 1)
      } else if (selectedProjects.value.length >= maxSelection) {
        showModalRef.value = true
      } else {
        selectedProjects.value!.push(url)
      }

      // TODO: remplacer par une mutation ?
      ctx.emit('selection:update', selectedProjects.value)
    }

    const close = () => {
      closeModal()
      ctx.root.$ee.$emit('selection:validate', selectedProjects.value)
      ctx.emit('close')
    }

    const closeModal = () => (showModalRef.value = false)

    return {
      ...useGetters(['content']),
      grid,
      setCategory,
      selectedProjects,
      updateSelection,
      close,
      category,
      showModalRef,
      closeModal,
    }
  },
})
