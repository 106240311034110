import VueRouter from 'vue-router'
import { Store } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'

import { langAvailable, langDefault } from '@/inc/app.config'
import { loadLanguageAsync, setI18nLanguage } from '@/inc/i18n'
import { fetch, logger, push } from '@/inc/utils'
import { Language } from '@/inc/types'
import { RootState } from '@/inc/store'
import { ResourceSingle } from '@/inc/store/modules/resource'

export const guards = (router: VueRouter, store: Store<RootState>) => {
  // Log
  router.beforeEach((to, from, next) => {
    logger.trace('[guards] from', from.name, 'to', to.name)
    next()
  })

  // Debug mode
  router.beforeEach((to, from, next) => {
    if (
      router.app?.$debug &&
      (from.query.debug !== undefined || to.query.debug !== undefined)
    ) {
      router.app.$debug = true
    }

    if (to.query.debug === undefined && from.query.debug !== undefined) {
      const query = { ...to.query, debug: from.query.debug }

      next({
        name: to.name as string,
        path: to.path,
        hash: to.hash,
        query,
        params: to.params,
      })
    } else {
      next()
    }
  })

  // Manage languages
  if (langAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      await loadLanguageAsync(langNext)
        .then(langLoaded => setI18nLanguage(langLoaded, router.$$type, store))
        .then(lang => {
          if (langHasChanged) {
            store.commit('user/SET_USER', lang)

            return store.dispatch('fetchChrome', { lang })
          }

          return Promise.resolve()
        })
        .catch(logger.error)

      next()
    })

    router.afterEach((to, from) => {
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang

      /**
       * If language has changed and GMaps has been loaded
       * Delete everything to get a new instance with the correct language
       * This fix:
       *  - map disappearing
       *  - `Loader must not be called again with different options` error
       *     @see https://github.com/googlemaps/js-api-loader/blob/master/src/index.ts#L287
       */
      if (langHasChanged && (Loader as any).instance) {
        console.log('GMAP reset')
        ;(Loader as any).instance.reset()
        delete (Loader as any).instance
        // Can be deleted here because it is needed for removing markers listeners
        // delete window.google?.maps
      }

      /**
       * If language has changed, update Cookiebot language
       * Use data-culture attribute to set the new language
       * and delete culture query param from src
       */
      if (langHasChanged) {
        const cookiebotScript = document?.querySelector('script#Cookiebot') as
          | HTMLScriptElement
          | undefined

        console.log('[guards] update CookieBot language', {
          cookiebotScript,
          newLang: lang,
        })

        if (cookiebotScript) {
          // Clean src
          const src = new URL(cookiebotScript.src)
          const srcParams = new URLSearchParams(src.search)

          srcParams.delete('culture')
          src.search = srcParams.toString()
          cookiebotScript.src = src.href

          // Add/update data-culture
          cookiebotScript.dataset.culture = lang

          // If popup is visible before lang change, we need to update its language:
          // the popup needs to be hidden, then triggered again
          if (window.CookiebotDialog?.visible) {
            window.Cookiebot?.hide()
            window.Cookiebot?.show()
          }
        }
      }
    })
  }

  // Fetch content
  router.beforeEach(async (to, _from, next) => {
    const isSpaFallback =
      router.$$type === 'client' && document.body.dataset.rendering === 'SPA'
    // Check if fetch is needed:
    // - on SPA fallback
    // - on second rendering ($resource is defined), client side
    const needFetch =
      isSpaFallback ||
      (router.$resource !== undefined && router.$$type === 'client')

    if (!needFetch) {
      router.$resource = {} as ResourceSingle

      return next()
    }

    // Fetch page resource
    // resource is added through `router`
    try {
      await fetch(to, router, false)
    } catch (error) {
      console.error(error)
    }

    return next()
  })

  // GTM - Analytics
  router.afterEach(to => {
    if (router.$$type === 'client') {
      // GTM: page view
      const layer = {
        event: 'page_view',
        pageTitle: router.$resource.meta?.title || document.title,
        pageLocation: window.location.href,
        pagePath: to.fullPath,
      }

      push(layer)
    }
  })
}
