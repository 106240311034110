























































import type { Picture } from '@/inc/types'
import {
  defineComponent,
  PropType,
  ref,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
import { defaultSets } from '@/inc/directives/src'

export default defineComponent({
  name: 'UiPicture',
  components: {},
  props: {
    content: {
      type: Object as PropType<Picture>,
      required: true,
    },
    sizes: {
      type: String,
      required: false,
      default: '100vw',
    },
    sets: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => defaultSets,
    },
    deferredLoading: {
      type: Number,
      required: false,
      default: 0,
    },
    fetchpriority: {
      type: String as PropType<'auto' | 'low' | 'high'>,
      required: false,
      default: 'auto',
    },
    cover: {
      type: Boolean,
      required: false,
      default: false,
    },
    caption: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  setup(props, { emit }) {
    const rootEl = ref<HTMLElement | null>(null)
    const imgEl = ref<HTMLImageElement | null>(null)
    const isLoaded = ref(false)
    const isVisible = ref(false)
    const sizeData = ref('100px')

    // On appear, load image with real size
    const onAppear = () => {
      isVisible.value = true
      sizeData.value = props.sizes
      emit('appear')

      if (imgEl.value?.complete) {
        onLoaded()
      } else {
        imgEl.value?.addEventListener('load', onLoaded)
      }

      // Remove automatic opacity setting from v-appear
      // rootEl.value?.removeAttribute('style')
    }

    // Set loaded status
    const onLoaded = () => {
      // Prevent load event from being emitted twice
      if (isLoaded.value) {
        return
      }

      isLoaded.value = true
      emit('load')
    }

    onMounted(() => {
      // If image is cached, it is already loaded
      if (imgEl.value?.complete) {
        onLoaded()
      } else {
        imgEl.value?.addEventListener('load', onLoaded)
      }

      // Handle deferred loading
      if (props.deferredLoading) {
        setTimeout(() => {
          onAppear()
        }, props.deferredLoading)
      }

      // Remove automatic opacity setting from v-appear
      rootEl.value?.removeAttribute('style')
    })

    onBeforeUnmount(() => {
      imgEl.value?.removeEventListener('load', onLoaded)
    })

    return {
      rootEl,
      imgEl,
      isLoaded,
      isVisible,
      sizeData,
      onAppear,
    }
  },
})
