







































































































import type { FlexibleGallery, GalleryItem, Picture } from '@/inc/types'

import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@vue/composition-api'
import { horizontalLoop } from '@/inc/vendor/gsap-extra/utils/loop.js'

import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/dist/ScrollTrigger.js'
gsap.registerPlugin(ScrollTrigger)

import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexibleGallery',
  components: { UiPicture },
  props: {
    content: {
      type: Object as () => FlexibleGallery,
      required: true,
    },
  },
  setup() {
    // Template refs
    const rootElRef = ref<HTMLElement | null>(null)
    const listElRef = ref<HTMLElement | null>(null)
    const itemsElRef = ref<HTMLElement[]>([])

    // Variables
    let loop: ReturnType<typeof gsap.timeline>
    let scrollTrigger: ReturnType<typeof ScrollTrigger.create>
    let imgsEl: HTMLImageElement[] | undefined = []

    const isPicture = (item: Picture | GalleryItem): item is Picture =>
      Boolean((item as Picture).src)

    // Helper to get img ratio
    const getRatio = (img: Picture) => `${img.width}/${img.height}`

    // Init scrolling gallery
    const initGallery = () => {
      if (!rootElRef.value) {
        return
      }

      loop = horizontalLoop(itemsElRef.value, {
        paused: true,
        draggable: true,
        inertia: false,
        speed: 0.5,
        repeat: -1,
        // TODO : fix img parallax when there is a great selection
        // Temporarily disabled
        /* eslint-disable indent */
        // onUpdate:
        //   imgsEl && imgsEl.length > 0
        //     ? () => {
        //         // Add parallax on imgs
        //         itemsElRef.value.forEach(itemEl => {
        //           gsap.set(itemEl, {
        //             '--img-translate-x': `${itemEl._gsap.xPercent * -0.01}%`,
        //           })
        //         })
        //       }
        //     : null,
        /* eslint-enable indent */
      })

      // Set ST to prevent the carousel playing while not in viewport
      scrollTrigger = ScrollTrigger.create({
        trigger: listElRef.value,
        start: 'top bottom',
        end: 'bottom top',
        onEnter: () => loop && loop.play(),
        onEnterBack: () => loop && loop.play(),
        onLeave: () => loop && loop.pause(),
        onLeaveBack: () => loop && loop.pause(),
      })
    }

    const onAppear = (el: Element) => {
      gsap.fromTo(el, { opacity: 0 }, { opacity: 1, duration: 1.2 })
    }

    // Pause carousel on card hover
    const onMouseOver = () => {
      if (!loop || !loop.isActive()) {
        return
      }

      loop.pause()
    }

    // Restart carousel on a card mouseout
    const onMouseLeave = () => {
      if (!loop || loop.isActive()) {
        return
      }

      loop.play()
    }

    onMounted(() => {
      // Get imgs from items
      const imgs = rootElRef.value?.querySelectorAll('img[data-gallery-image]')

      if (imgs && imgs.length) {
        imgsEl = [...(Array.from(imgs) as HTMLImageElement[])]
      }

      // Wait for all imgs to be loaded before creating gallery
      const imgPromises: Promise<void>[] = []

      if (imgsEl) {
        imgsEl.forEach(img => {
          imgPromises.push(
            new Promise(resolve => {
              if (img.complete) {
                // if img is cached, resolve immediately
                resolve()
              } else {
                // or wait for it to load
                img.onload = () => {
                  resolve()
                }
              }
            })
          )
        })
      }

      // Init gallery when all pictures are ready
      Promise.all(imgPromises).then(() => {
        initGallery()
      })
    })

    onUnmounted(() => {
      if (scrollTrigger) {
        scrollTrigger.kill()
      }
    })

    return {
      rootElRef,
      listElRef,
      itemsElRef,
      isPicture,
      getRatio,
      onAppear,
      onMouseOver,
      onMouseLeave,
    }
  },
})
