











import { defineComponent, PropType } from '@vue/composition-api'

interface ShowroomInfo {
  title: string
  htmltext: string
}

export default defineComponent({
  name: 'ShowroomInfo',
  components: {},
  props: {
    content: {
      type: Object as PropType<ShowroomInfo>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
