/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray, isObject } from '@/inc/utils'

export const toKebab = (str: string): string =>
  str
    .replace(/^\w/, chr => chr.toLowerCase())
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase()
export const toCamel = (s: string) =>
  s
    .replace(/^\w/, chr => chr.toLowerCase())
    .replace(/([-_][a-z])/gi, $1 =>
      $1.toUpperCase().replace('-', '').replace('_', '')
    )

export const toPascal = (str: string): string =>
  toCamel(str).replace(/^\w/, chr => chr.toUpperCase())

export const pascalToKebab = (str: string): string => toKebab(str)
export const camelToKebab = (str: string): string => toKebab(str)

export const keysToCamel = (o: any) => {
  if (isObject(o)) {
    const n = {} as Record<string, any>

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k])
    })

    return n
  } else if (isArray(o)) {
    return o.map((i: any) => keysToCamel(i))
  }

  return o
}

export const camelToSnake = (str: string) =>
  str.replace(/([A-Z])/g, '_$1').toLowerCase()
