






















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'GTitle',
  props: {
    content: {
      type: String,
      Default: 'Page title',
    },
    tag: {
      type: String,
      default: 'h1',
    },
  },
})
