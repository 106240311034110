






























import { useGetters, useMutations } from '@u3u/vue-hooks'
import { defineComponent, onMounted, ref } from '@vue/composition-api'

import { push, GtmLayer } from '@/inc/utils'
import { FlexibleVideo } from '@/inc/types'

import gsap from 'gsap'
import UiPicture from '@/components/ui/Picture.vue'

export default defineComponent({
  name: 'FlexibleVideo',
  components: { UiPicture },
  props: {
    content: {
      type: Object as () => FlexibleVideo,
      required: true,
    },
  },
  setup(props, ctx) {
    const { content } = useGetters(['content'])
    const { SET_VIDEO: setVideo } = useMutations('ui', ['SET_VIDEO'])
    const root = ref<HTMLElement | null>(null)

    const openVideo = (label: string) => {
      ctx.emit('video', props.content.youtubeId)
      setVideo(props.content.youtubeId)

      if (root.value) {
        const layer: GtmLayer = {
          event: 'cta_click',
          clickText: label,
          ctaLabel: content.value.title,
        }

        push(layer)
      }
    }

    onMounted(() => {
      if (!root.value) {
        return
      }

      const video = root.value.querySelector('.flexible-video__video')
      const picture = root.value.querySelector('.flexible-video__picture')
      const container = video ? video : picture
      const button = root.value.querySelector('.flexible-video__button')

      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([container, button], { clearProps: 'all' })
        },
      })

      tl.from(container, { y: 50, opacity: 0 })
      tl.from(button, {
        opacity: 0,
        scale: 2,
        ease: 'power4.out',
        duration: 0.5,
      })
    })

    return {
      root,
      openVideo,
    }
  },
})
