import Vue from 'vue'
import Vuex, { Plugin, Store, StoreOptions } from 'vuex'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

// Modules
import { chrome, ChromeState } from './modules/chrome'
import { resource, ResourceState } from './modules/resource'
import { version, VersionState } from './modules/version'
import steps, { StepsState } from './modules/steps'
import { ui, UiState } from './modules/ui'
import { moodbuilder, MoodbuilderState } from './modules/moodbuilder'
// End modules

/**
 * Store
 */
export interface RootState {
  chrome: ChromeState
  resource: ResourceState
  version: VersionState
  // Namespaced
  steps: StepsState
  ui: UiState
  moodbuilder: MoodbuilderState
}

Vue.use(Vuex)

export default function createStore(): Store<RootState> {
  const plugins: Plugin<RootState>[] = []

  const store: StoreOptions<RootState> = {
    modules: {
      chrome,
      resource,
      version,
      // Namespaced
      steps,
      ui,
      moodbuilder,
    },
    plugins,
  }

  return new Vuex.Store<RootState>(store)
}
