
































import type { Picture } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'
import { pushCta } from '@/inc/utils'

interface HeaderCta {
  label?: string
  title?: string
  url: string
  picture: Picture
  background?: string
}

export default defineComponent({
  name: 'ChromeHeaderCta',
  props: {
    content: {
      type: Object as PropType<HeaderCta>,
      required: true,
    },
    moodboard: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, ctx) {
    const onClick = (e: PointerEvent) => {
      const target = e.currentTarget || e.target

      if ((target as Element)?.classList.contains('router-link-exact-active')) {
        ctx.emit('click:active')
      }

      // Track click
      const { label, url, title } = props.content

      pushCta(
        {
          ctaLabel: label || title,
          ctaType: 'mega_menu',
          ctaUrl: url,
        },
        target as HTMLElement
      )
    }

    return {
      onClick,
    }
  },
})
