



















import { defineComponent, ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import gsap, { TimelineLite } from 'gsap'

export default defineComponent({
  name: 'ChromeHeaderTrigger',
  setup(_props, ctx) {
    const { isMenuOpen } = useGetters('ui', ['isMenuOpen'])
    const triggerElRef = ref<HTMLElement | null>(null)
    const isOpen = ref(isMenuOpen.value)
    let openTl: TimelineLite
    let closeTl: TimelineLite

    // "opens trigger": from the three lines to the cross
    const initOpenTl = () => {
      const lines = triggerElRef.value?.querySelectorAll('[class*="line--"]')
      const label = triggerElRef.value?.querySelector('.header-trigger__label')

      if (!lines || !label) {
        return
      }

      openTl = gsap.timeline({
        onComplete: () => {
          gsap.set([lines, label], { clearProps: 'all' })
        },
      })
      openTl
        .to(lines, {
          x: 50,
          stagger: 0.1,
          duration: 0.5,
          ease: 'expo.in',
        })
        .add('cross')
        .set(
          lines[0],
          {
            x: -20,
            y: -20,
            xPercent: -50,
            left: '50%',
            rotate: '45deg',
            marginTop: 0,
          },
          'cross'
        )
        .set(
          lines[1],
          {
            x: 20,
            y: -20,
            xPercent: -50,
            left: '50%',
            rotate: '-45deg',
          },
          'cross'
        )
        .to(
          [lines[0], lines[1]],
          {
            x: 0,
            y: 0,
            ease: 'power4.out',
            stagger: 0.1,
            duration: 0.25,
          },
          'cross'
        )
        .add(() => (isOpen.value = true)) // change label
        .to(label, { clipPath: 'inset(0 0 0 0%)' }, 'cross+=0.25')
    }

    // "closes trigger": from the cross to the three lines
    const initCloseTl = () => {
      const lines = triggerElRef.value?.querySelectorAll('[class*="line--"]')
      const label = triggerElRef.value?.querySelector('.header-trigger__label')

      if (!lines || !label) {
        return
      }

      closeTl = gsap.timeline({
        onComplete: () => {
          isOpen.value = false
          gsap.set([lines, label], { clearProps: 'all' })
        },
      })

      closeTl
        .to(label, { clipPath: 'inset(0 0 0 100%)' })
        .to(
          lines[1],
          {
            x: -30,
            y: 30,
            ease: 'expo.in',
            duration: 0.25,
          },
          0
        )
        .to(
          lines[0],
          {
            x: 30,
            y: 30,
            ease: 'expo.in',
            duration: 0.25,
          },
          0.1
        )
        .add('lines')
        .set([lines, label], { clearProps: 'all' }, 'lines')
        .add(() => (isOpen.value = false), 'lines')
        .set(
          lines,
          {
            x: 0,
            y: 0,
            xPercent: -100,
            rotate: 0,
          },
          'lines'
        )
        .to(
          lines,
          {
            xPercent: 0,
            stagger: 0.1,
            duration: 0.75,
            ease: 'power4.out',
          },
          'lines+=0.1'
        )
    }

    // Fires open animation
    // Triggered by onClick
    const open = () => {
      // Cancel close animation if it is running
      if (closeTl && closeTl.isActive()) {
        closeTl.kill()
      }

      if (openTl) {
        openTl.invalidate().restart()
      } else {
        initOpenTl()
      }
    }

    // Fires close animation
    // Triggered by onClick
    const close = () => {
      // Cancel open animation if it is running
      if (openTl && openTl.isActive()) {
        openTl.kill()
      }

      if (closeTl) {
        closeTl.invalidate().restart()
      } else {
        initCloseTl()
      }
    }

    const onClick = () => {
      ctx.emit('click')

      if (isMenuOpen.value) {
        open()
      } else {
        close()
      }
    }

    return {
      triggerElRef,
      isMenuOpen,
      onClick,
      isOpen,
      open,
      close,
    }
  },
})
