var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"grid",staticClass:"project-grid is-loading"},[_c('TransitionGroup',{staticClass:"masonry-grid",attrs:{"tag":"ul","name":"slide-up"}},[_vm._l((_vm.projects.slice(0, _vm.numberToDisplay)),function(project){return _c('li',{directives:[{name:"aware",rawName:"v-aware",value:({
        appear: {
          once: true,
          rootMargin: '0px 0px',
          threshold: [1],
        },
      }),expression:"{\n        appear: {\n          once: true,\n          rootMargin: '0px 0px',\n          threshold: [1],\n        },\n      }"}],key:_vm.isPicture(project) ? project.rawUrl : project.url,staticClass:"masonry-grid__item",on:{"appear":_vm.onCardAppear}},[(_vm.isPicture(project) || _vm.isForm)?_c('div',{staticClass:"masonry-grid__item__picture",class:{ inspiration: _vm.isForm }},[_c('UiPicture',{staticClass:"br-small",attrs:{"content":_vm.isPicture(project) ? project : project.picture,"sets":['320', '480', '640'],"sizes":"(min-width: 1024px) 640w, (min-width: 480px) 50vw, 100vw"},on:{"img:load":_vm.refreshLayout}}),(_vm.isForm && !_vm.isPicture(project))?_c('button',{staticClass:"masonry-grid__item__button",class:{ selected: _vm.selection.includes(project.url) },on:{"click":function($event){$event.preventDefault();return _vm.onProjectClick({ project: project })}}},[_c('label',{staticClass:"masonry-grid__item__button__label"},[_c('span',{staticClass:"masonry-grid__item__button__label__icons"},[_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"plus",attrs:{"symbol":"ui-plus","size":"0 0 18 18"}}),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"check",attrs:{"symbol":"ui-check","size":"0 0 18 18"}})]),_c('span',[_vm._v(" "+_vm._s(_vm.selection.includes(project.url) ? _vm.$t('added') : _vm.$t('add'))+" ")])])]):_vm._e()],1):_c('ProjectCard',{attrs:{"content":project,"event":""},on:{"img:load":_vm.refreshLayout,"card:click":function($event){return _vm.onProjectClick($event)}}})],1)}),_c('li',{key:"grid-sizer",staticClass:"grid-sizer"})],2),(
      !_vm.pictures &&
      (_vm.projects.length < _vm.totalPosts || _vm.projects.length > _vm.numberToDisplay)
    )?_c('div',{staticClass:"project-grid__loadmore mt-xl"},[_c('GAction',{attrs:{"content":{
        label: _vm.$t('load-more-projects'),
        tag: 'button',
        modifiers: ['small'],
      },"prevent-tracking":true},nativeOn:{"click":function($event){$event.preventDefault();return _vm.loadMore.apply(null, arguments)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }