var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumbs"},[_c('ul',{staticClass:"crumbs"},[(!_vm.crumbs || !_vm.crumbs.length)?_c('li',{staticClass:"crumbs__item"},[_c('GAction',{staticClass:"g-label",attrs:{"content":{
          label: _vm.$t('home'),
          url: ("/" + (_vm.$route.params.lang) + "/"),
          modifiers: ['colored', 'reverse'],
          icon: 'leftArrow',
        }}})],1):_vm._e(),_vm._l((_vm.crumbs),function(crumb){return _c('li',{key:crumb.value,staticClass:"crumbs__item"},[(crumb.value || crumb.url)?_c('GAction',{staticClass:"g-label",attrs:{"content":{
          label: crumb.label,
          url: crumb.value ? crumb.value : crumb.url,
          modifiers: ['colored', 'reverse'],
          icon: 'leftArrow',
        }},nativeOn:{"click":function($event){return _vm.onClick.apply(null, arguments)}}}):_vm._e()],1)}),(_vm.meta.title && (!_vm.crumbs || !_vm.crumbs.length))?_c('li',{staticClass:"crumbs__item"},[_c('GAction',{staticClass:"g-label",attrs:{"content":{
          label: _vm.meta.title.replace(' - Camber', ''),
          url: _vm.$route.fullPath,
          modifiers: ['colored', 'reverse'],
          icon: 'leftArrow',
        }}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }