















































































































import { defineComponent } from '@vue/composition-api'
import { Picture } from '@/inc/types'

export default defineComponent({
  name: 'GPicture',
  props: {
    content: {
      type: Object as () => Picture,
      Default: {} as Picture,
    },
    full: {
      type: Boolean,
      default: false,
    },
    cover: {
      type: Boolean,
      default: false,
    },
    sets: {
      type: Array as () => string[],
      default: () => [
        '160',
        '240',
        '320',
        '480',
        '640',
        '960',
        '1280',
        '1280',
        '1440',
        '1600',
      ],
    },
  },
  setup(_props, ctx) {
    const onLoad = (e: Event) => {
      ctx.emit('img:load', e.target)
    }

    return { onLoad }
  },
})
