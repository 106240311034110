






import { computed, defineComponent, PropType } from '@vue/composition-api'

interface WysiwygContent {
  htmltext: string
}

export default defineComponent({
  name: 'GWysiwyg',
  props: {
    wysiwyg: {
      type: String,
      default: '',
      required: false,
    },
    content: {
      type: Object as PropType<WysiwygContent>,
      required: false,
      default: () => ({} as WysiwygContent),
    },
  },
  setup(props) {
    const runtimeComponent = computed(() => ({
      components: {},
      template: `<div>${props.wysiwyg || props.content?.htmltext}</div>`,
    }))

    return {
      runtimeComponent,
    }
  },
})
