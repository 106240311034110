







































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { PSingle } from '@/inc/types'

import UiPrices from '@/components/ui/Prices.vue'
import UiPicture from '@/components/ui/Picture.vue'
import UiMasonry from '@/components/ui/Masonry.vue'
import FlexibleBeforeAfter from '@/components/flexible/BeforeAfter.vue'

export default defineComponent({
  name: 'ProjectSingle',
  components: {
    UiPrices,
    UiPicture,
    UiMasonry,
    FlexibleBeforeAfter,
  },
  props: {
    popup: {
      type: Object as () => PSingle,
      required: false,
    },
  },
  setup(props) {
    const content = props.popup ? props.popup : useGetters(['content']).content

    return {
      content,
    }
  },
})
