




import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'CustomScript',
  components: {},
  props: {
    src: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
