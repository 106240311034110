var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"form-select",attrs:{"tag":"div","rules":{
    minmax: { min: 2, max: 320 },
    select: _vm.$attrs['type'] === 'select',
    required: _vm.required,
  }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
  var invalid = ref.invalid;
return [_c('div',{staticClass:"form-select__label"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"form-select__required"},[_vm._v("*")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"select-control",attrs:{"type":"text","disabled":"","name":_vm.name,"rules":_vm.required},domProps:{"value":(_vm.selected)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selected=$event.target.value}}}),_c('v-select',{ref:"select",staticClass:"select__input",class:{ 'is-invalid': invalid },attrs:{"name":_vm.name,"label":"label","filterable":false,"searchable":false,"options":_vm.options,"placeholder":_vm.placeholder,"append-to-body":_vm.autoPlacement,"calculate-position":_vm.calculatePosition},on:{"input":function($event){return _vm.selectedValue(_vm.selected)},"blur":function($event){return _vm.selectedValue(_vm.selected)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(errors)?_c('FormFeedback',{staticClass:"form-select__error",attrs:{"content":errors[0]}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }