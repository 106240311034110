


























import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { Link } from '@/inc/types'
import { pushCta } from '@/inc/utils'

export default defineComponent({
  name: 'ChromeHeaderLegal',
  setup(_props, ctx) {
    // Trigger cookiebot preferences popup
    const onCookiesClick = () => window.Cookiebot?.renew()

    const onClick = (e: PointerEvent, link: Link) => {
      const { currentTarget } = e

      if (currentTarget) {
        // Close menu if we click on the link of the current page
        if (
          (currentTarget as Element).classList.contains(
            'router-link-exact-active'
          )
        ) {
          ctx.emit('click:active')
        }

        // Track click
        const { label, url } = link

        pushCta(
          {
            ctaLabel: label,
            ctaType: 'mega_menu',
            ctaUrl: url,
          },
          currentTarget as HTMLElement
        )
      }
    }

    return {
      ...useGetters(['chrome']),
      onCookiesClick,
      onClick,
    }
  },
})
