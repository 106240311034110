

























import type { Link } from '@/inc/types'
import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'
import { push, GtmLayer } from '@/inc/utils'

interface SocialLink extends Link {
  type: string
}

export default defineComponent({
  name: 'ChromeSocials',
  setup() {
    const pushDatalayer = (link: SocialLink) => {
      const layer: GtmLayer = {
        event: 'social_click',
        clickTitle: link.type,
      }

      push(layer)
    }

    return {
      pushDatalayer,
      ...useGetters(['chrome']),
    }
  },
})
