



























import { defineComponent } from '@vue/composition-api'
import { ValidationProvider, setInteractionMode, extend } from 'vee-validate'
import FormFeedback from '@/components/form/Feedback.vue'

setInteractionMode('eager')
extend('checkboxRequired', {
  validate(value) {
    if (!value) {
      return 'form-terms'
    }

    return true
  },
  computesRequired: true,
  message: 'form-terms',
})

export default defineComponent({
  name: 'FormCheckbox',
  inheritAttrs: false,
  components: {
    ValidationProvider,
    FormFeedback,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
  },
  setup(props, ctx) {
    const value = props.checked

    const onChange = e => {
      ctx.emit('change', e.target.checked)
    }

    const onFocus = () => {
      ctx.emit('focus')
    }

    return {
      value,
      onChange,
      onFocus,
    }
  },
})
