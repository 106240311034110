var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{directives:[{name:"aware",rawName:"v-aware"}],ref:"rootRef",staticClass:"header",class:[
    {
      'menu-open': _vm.isMenuOpen,
      down: _vm.yPosition > 50,
      blog: _vm.template === 'blog',
    },
    _vm.headerColor ],on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"header-inner wrapper"},[_c('RouterLink',{staticClass:"header__logo",attrs:{"to":{ name: 'Home' }}},[_c('span',{staticClass:"header__logo__label"},[_vm._v("Camber")]),_c('svg',{directives:[{name:"svg",rawName:"v-svg"}],staticClass:"header__logo__icon icon",attrs:{"size":"0 0 100 20","symbol":"camber-logo"}})]),_c('div',{staticClass:"header__actions"},[_c('ChromeHeaderLang',{staticClass:"header__lang"}),_c('div',{staticClass:"header__cta"},[_c('GAction',{attrs:{"content":Object.assign({}, _vm.chrome.ctas.headerRdv,
            {modifiers: ['btn', 'filled']}),"prevent-tracking":true},nativeOn:{"click":function($event){return _vm.pushDatalayer.apply(null, arguments)}}})],1),_c('ChromeHeaderTrigger',{ref:"triggerRef",on:{"click":_vm.onTriggerClick}})],1)],1),_c('transition',{attrs:{"css":false},on:{"enter":_vm.onMenuEnter,"leave":_vm.onMenuLeave}},[_c('ChromeHeaderMenu',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMenuOpen),expression:"isMenuOpen"}],ref:"menuRef"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }