


















































































































































































































































































































































































































































































import type { GActionContent, Icon } from '@/inc/types'
import {
  computed,
  defineComponent,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  createElement as h,
  ref,
} from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'

// Icon values
const defaultViewbox = '0 0 24 24'
// prettier-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RegisteredIcons = Record<string, any>
const registeredIcons: RegisteredIcons = {
  arrow: { symbol: 'ui-arrow-small-right' },
  leftArrow: { symbol: 'ui-arrow-small-left' },
  downArrow: { symbol: 'ui-arrow-down' },
  download: { symbol: 'ui-download' },
  external: { symbol: 'ui-external-link' },
  messenger: { symbol: 'ui-messenger' },
  send: { symbol: 'ui-send' },
  burger: { symbol: 'ui-burger-menu' },
  close: { symbol: 'ui-close' },
  share: { symbol: 'ui-share' },
  link: { symbol: 'ui-link' },
  play: { symbol: 'ui-play' },
  plus: { symbol: 'ui-plus' },
  bin: { symbol: 'ui-bin' },
  tooltip: { symbol: 'ui-tooltip' },
  facebook: { symbol: 'social-facebook' },
  instagram: { symbol: 'social-instagram' },
  linkedin: { symbol: 'social-linkedin' },
  pinterest: { symbol: 'social-pinterest' },
  twitter: { symbol: 'social-twitter' },
  youtube: { symbol: 'social-youtube' },
}

// Render functions
const props = {
  url: { type: String },
  to: { type: Object },
  target: { type: String },
  type: { type: String as () => 'submit' | 'button' | 'reset' },
  click: { type: Function },
  enter: { type: Function },
  leave: { type: Function },
}

const ActionAnchor = defineComponent({
  name: 'ActionAnchor',
  props,
  setup: (props, ctx) => () =>
    (
      <a
        href={props.url}
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
      >
        {ctx.slots.default()}
      </a>
    ),
})

const ActionOutside = defineComponent({
  name: 'ActionOutside',
  props,
  setup: (props, ctx) => () =>
    (
      <a
        href={props.url}
        target={props.target}
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
        rel="noopener noreferrer"
      >
        {ctx.slots.default()}
      </a>
    ),
})

const ActionRouterLink = defineComponent({
  name: 'ActionRouterLink',
  props,
  setup: (props, ctx) => () =>
    (
      <router-link
        to={props.url || props.to}
        nativeOnClick={props.click}
        nativeOnMouseenter={props.enter}
        nativeOnMouseleave={props.leave}
      >
        {ctx.slots.default()}
      </router-link>
    ),
})

const ActionButton = defineComponent({
  name: 'ActionButton',
  props,
  setup: (props, ctx) => () =>
    (
      <button
        type={props.type}
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
      >
        {ctx.slots.default()}
      </button>
    ),
})

export default defineComponent({
  name: 'GAction',
  props: {
    content: {
      type: Object as () => GActionContent,
      required: true,
      validator: (value: GActionContent) => {
        if (!value.to && (!value.tag || value.tag === 'a')) {
          !value.url &&
            console.warn('[GAction] url property is mandatory for links')

          return value.url !== undefined
        }

        return true
      },
    },
    preventTracking: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const rootElRef = ref<InstanceType<
      | typeof ActionAnchor
      | typeof ActionOutside
      | typeof ActionRouterLink
      | typeof ActionButton
    > | null>(null)
    const {
      label,
      url = '',
      to = null,
      tag,
      type = '',
      target = '',
      icon: iconData,
      iconsup,
      modifiers = [],
    } = props.content
    const clickHandler = (e: MouseEvent) => {
      // Add global tracking event
      if (!props.preventTracking && rootElRef.value?.$el) {
        const el = rootElRef.value?.$el as HTMLElement
        const layer: Partial<GtmCta> = {
          ctaLabel: label,
          ctaType: 'global',
          ctaUrl: url,
        }

        pushCta(layer, el)
      }

      ctx.emit('on-click', e)
    }
    const enterHandler = (e: MouseEvent) => ctx.emit('on-enter', e)
    const leaveHandler = (e: MouseEvent) => ctx.emit('on-leave', e)

    // Specific click handlers
    // Brochure / Newsletter shortcodes handling
    const popupClickHandler = (e: MouseEvent) => {
      e.preventDefault()
      ctx.emit('on-click', e)

      const { currentTarget } = e

      if (!currentTarget) {
        return
      }

      const { popup } = (currentTarget as HTMLButtonElement).dataset

      // Open popup
      if (popup?.includes('brochure')) {
        ctx.root.$ee.$emit('popup-brochure:open')
      } else if (popup?.includes('newsletter')) {
        ctx.root.$ee.$emit('popup-newsletter:open')
      }
    }

    const isButton = tag === 'button'
    const isAnchor = to === null && url && /^(http|#|tel|mailto)/.test(url)
    const isOutside = target === '_blank'
    const hasPopup = url?.includes('#brochure') || url?.includes('#newsletter')

    const excludeLink = modifiers.includes('no-link')
    const excludeBtn = modifiers.includes('no-btn')

    if (!isButton && !excludeLink) {
      modifiers.push('link')
    }
    if (isButton && !excludeBtn) {
      modifiers.push('btn')
    }

    let component = ActionRouterLink
    if (isButton || hasPopup) {
      component = ActionButton
    } else if (isOutside) {
      component = ActionOutside
    } else if (isAnchor || (!url && !to)) {
      component = ActionAnchor
    }

    const icon: Icon = {} as Icon
    if (iconData) {
      modifiers.push('icon')

      if (typeof iconData === 'string' && registeredIcons[iconData]) {
        modifiers.push(iconData as string)
        icon.name = registeredIcons[iconData].symbol || ''
        icon.viewbox = registeredIcons[iconData].viewbox || defaultViewbox
      } else {
        icon.name =
          (iconData as Icon).name ||
          registeredIcons[(iconData as Icon).slug || 'arrow']?.symbol
        icon.viewbox = (iconData as Icon).viewbox || defaultViewbox
        modifiers.push(icon.name)
      }
    }

    const loadingLabel = computed(
      () => props.content.loadingLabel ?? ctx.root.$t('form-loading')
    )

    return {
      rootElRef,
      clickHandler,
      popupClickHandler,
      enterHandler,
      leaveHandler,
      component,
      label,
      url,
      to: to || {},
      target,
      type,
      icon,
      iconsup,
      modifiers,
      loadingLabel,
      hasPopup,
    }
  },
})
