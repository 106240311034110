import { VNode } from 'vue'

// Directives
export interface VueDisplayData {
  viewport: number
  el: HTMLElement
}

export default {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // bind(el: HTMLElement, binding: any, vnode: VNode) {
  //   console.log('viewoport', el)
  // },

  // unbind(el: HTMLElement) {
  //   console.log('viewoport', el)
  // },

  bind(el, binding, vnode) {
    console.log('bind', el)
  },
  inserted(el, binding, vndoe) {
    console.log('inserted')
  },
  updated(el, binding, vnode) {
    console.log('updated')
  },
  componentUpdated(el, binding, vnode, oldVnode) {
    console.log('componentUpdated')
  },
}
