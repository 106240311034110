




































import type { Link, FlexibleGallery as Gallery } from '@/inc/types'
import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import FlexibleQuote from '@/components/flexible/Quote.vue'
import FlexibleGallery from '@/components/flexible/Gallery.vue'
import FlexibleVideo from '@/components/flexible/Video.vue'

export interface ExpertSingle {
  breadcrumb: Link[]
  title?: string
  htmltext?: string
  gallery?: Gallery[]
}

export default defineComponent({
  name: 'ExpertSingle',
  components: {
    FlexibleQuote,
    FlexibleGallery,
    FlexibleVideo,
  },
  props: {
    popup: {
      type: Object as () => ExpertSingle,
      required: false,
    },
  },
  setup(props) {
    const content = props.popup ? props.popup : useGetters(['content']).content

    return {
      content,
    }
  },
})
