






























































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import axios from 'axios'

const positions = ['right', 'left'] as const

type EnvsData = Record<string, Record<string, string>>
type EnvsValues = Record<string, string>
type EnvsPosition = (typeof positions)[number]

export default defineComponent({
  name: 'EnvSwitcher',
  props: {
    position: {
      type: String as () => EnvsPosition,
      default: 'right',
      validator(pos: string) {
        return positions.includes(pos as EnvsPosition)
      },
    },
  },
  setup(_props, ctx) {
    const cookieOptions = {
      domain: '.epic-sys.io',
      secure: true,
      sameSite: 'none',
      path: '/',
    }
    const isActive = process.env.VUE_APP_RELEASE === 'staging'
    const { $cookie, $logger } = ctx.root
    const isOpen = ref(false)
    const envs = ref<EnvsData>({})
    const currents = ref<EnvsValues>({})
    const selection = ref<EnvsValues>()

    const onToggle = () => {
      isOpen.value = !isOpen.value

      if (!isOpen.value) {
        selection.value = undefined
      }
    }
    const onChange = (label: string) => {
      const cookieName = `epic-env-${label}`

      if (selection.value) {
        selection.value[cookieName] = currents.value[label]
      } else {
        selection.value = { [cookieName]: currents.value[label] }
      }
    }

    const onConfirm = () => {
      if (!selection.value) {
        return
      }

      Object.keys(selection.value).forEach(cookieName => {
        $cookie.set(cookieName, selection.value![cookieName], cookieOptions)
      })
      isOpen.value = false
      selection.value = undefined
      window.location.reload()
    }

    onMounted(async () => {
      if (!isActive) {
        return
      }

      try {
        await axios.get('/envs.json').then(res => {
          const { data } = res
          const currentsData: EnvsValues = {}

          envs.value = { ...data }

          Object.keys(envs.value).forEach(label => {
            const cookieName = `epic-env-${label}`

            if ($cookie.get(cookieName)) {
              currentsData[label] = $cookie.get(cookieName)
            } else {
              currentsData[label] = envs.value[label].staging
                ? 'staging'
                : Object.keys(envs.value[label])[0]
            }

            $cookie.set(cookieName, currentsData[label], cookieOptions)
          })

          currents.value = currentsData
        })
      } catch (error) {
        $logger.warn('No ENVS found')
      }
    })

    return {
      isActive,
      isOpen,
      envs,
      currents,
      selection,
      onToggle,
      onChange,
      onConfirm,
    }
  },
})
