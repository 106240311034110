





























import { defineComponent, ref, Ref } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import { Language, Languages } from '@/inc/types'
import { setItem } from '@/inc/vendor/cookies'

export default defineComponent({
  name: 'ChromeHeaderLang',
  setup() {
    const { currentLang, languages } = useGetters([
      'currentLang',
      'languages',
    ]) as { currentLang: Ref<Language>; languages: Ref<Languages> }
    const isListOpen = ref(false)

    const toggleList = (e: MouseEvent) => {
      if (isListOpen.value) {
        close()
        ;(
          document.querySelector('.header-lang__current') as HTMLButtonElement
        ).blur()
      } else {
        open(e)
      }
    }

    const open = (e: MouseEvent) => {
      e.stopPropagation()
      isListOpen.value = true
      document.addEventListener('click', close)
    }

    const close = () => {
      isListOpen.value = false
      document.removeEventListener('click', close)
    }

    const onSelectLanguage = (combo: string) => {
      // Set language cookie
      const [lang] = combo.split('-')
      setItem('selected_language', lang, undefined, '/')
    }

    const onFocus = () => {
      isListOpen.value = true
    }

    const onBlur = () => {
      close()
    }

    return {
      currentLang,
      languages,
      toggleList,
      isListOpen,
      onFocus,
      onBlur,
      onSelectLanguage,
      ...useGetters(['chrome', 'currentLang']),
    }
  },
})
