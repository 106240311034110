










import { defineComponent, PropType } from '@vue/composition-api'

type PopupKind = 'newsletter' | 'brochure'

export default defineComponent({
  name: 'GPopupTrigger',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
    },
    popup: {
      type: String as PropType<PopupKind>,
      required: true,
    },
  },

  setup(props, ctx) {
    // Open popup
    const onClick = () => {
      switch (props.popup) {
        case 'brochure':
        case 'newsletter':
          ctx.root.$ee.$emit(`popup-${props.popup}:open`)
          break
        default:
          break
      }

      // NB: if you need to add a Camber Journal case,
      // you will need to add the popup somewhere in the chrome
    }

    return {
      onClick,
    }
  },
})
