
























































import gsap from 'gsap'
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
  PropType,
} from '@vue/composition-api'

import { campaignManager } from '@/inc/utils'

import ExpertSingle from '@/views/ExpertSingle.vue'
import ProjectSingle from '@/views/ProjectSingle.vue'
import ShowroomSingle from '@/views/ShowroomSingle.vue'
import FormProjectsOverlay from '@/components/form/ProjectsOverlay.vue'
import ChromeFooterOverlay from '@/components/chrome/footer/Overlay.vue'

export default defineComponent({
  name: 'GPopup',
  components: {
    ExpertSingle,
    ProjectSingle,
    ShowroomSingle,
    FormProjectsOverlay,
    ChromeFooterOverlay,
  },
  props: {
    content: {
      // TODO: improve any
      type: Object as PropType<any>,
      required: false,
    },
    template: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    const { $route, $router } = ctx.root
    const popup = ref()
    const inner = ref<HTMLElement | null>(null)
    const archiveUrl = $route.path
    let isOpen = false

    const handleClose = (e?: Event | KeyboardEvent | null) => {
      if ((e && (e!.target as HTMLElement).tagName === 'INPUT') || !isOpen) {
        return
      }

      if (
        e &&
        (e as KeyboardEvent).key &&
        (e as KeyboardEvent).key !== 'Escape'
      ) {
        return
      }

      close()
      history.replaceState({ name: 'archive' }, '', archiveUrl)
    }
    const close = () => {
      gsap.set(document.body, { clearProps: 'pointerEvents' })

      const pageItems = popup.value.querySelectorAll('.popup__inner__page > *')
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([popup.value, inner.value, document.body], {
            clearProps: 'all',
          })
          ctx.emit('popup:close')
        },
      })
      tl.to(
        pageItems,
        {
          y: 200,
          duration: 0.5,
          stagger: -0.05,
          ease: 'expo.in',
        },
        0
      )
      tl.to(
        inner.value,
        { opacity: 0, y: '100vh', duration: 0.5, ease: 'power4.in' },
        0
      )
      tl.to(popup.value, { opacity: 0, duration: 0.5 })
      tl.set(popup.value, { visibility: 'hidden' })
    }

    const emitSelection = (selection: string[]) => {
      ctx.emit('selection:update', selection)
    }

    // To be removed?
    // const onPopState = (e: PopStateEvent) => {
    //   console.log(e, isOpen)
    //   if (isOpen) {
    //     handleClose()
    //   }
    // }

    onMounted(() => {
      history.replaceState({ name: 'archive' }, '')

      const pageItems = popup.value.querySelectorAll('.popup__inner__page > *')
      const popupFooter = popup.value.querySelector(
        '.projects-overlay__validation'
      )

      const tl = gsap.timeline({
        onStart: () => {
          const url = props.content?.url ? props.content?.url : archiveUrl

          window.history.pushState({ name: 'popup' }, '', url)
          document.body.classList.add('popup-active')
          isOpen = true
          ctx.root.$ee.$emit('popup:open', close)
        },
        onComplete: () => {
          gsap.set(inner.value, { clearProps: 'all' })
          popup.value.focus()
        },
      })
      tl.set(popup.value, { visibility: 'visible', pointerEvents: 'all' })
      tl.from(popup.value, { opacity: 0, duration: 0.5 })
      tl.from(
        inner.value,
        {
          opacity: 0,
          y: '100vh',
          duration: 0.5,
          ease: 'power4.out',
        },
        0.5
      )
      tl.from(
        pageItems,
        {
          y: 150,
          duration: 1,
          stagger: 0.1,
          ease: 'power4.out',
        },
        0.5
      )

      if (popupFooter) {
        tl.from(
          popupFooter,
          {
            yPercent: 100,
            duration: 1,
            ease: 'power4.out',
          },
          '-=0.5'
        )
      }

      // Update campaign manager with popup campaigns
      if (props.content?.campaigns?.length) {
        campaignManager.update(props.content.campaigns)
      }

      // Add closing event listeners
      window.addEventListener('keyup', handleClose, { once: true })
      window.addEventListener('popstate', handleClose, { once: true })
      ctx.root.$ee.$on('popup:close', close)

      // Close popup on page change
      $router.beforeEach((_to, _from, next) => {
        close()
        next()
      })
    })

    onUnmounted(() => {
      isOpen = false
      document.body.classList.remove('popup-active')
      window.removeEventListener('keyup', handleClose)
      window.removeEventListener('popstate', handleClose)

      // Add new entry for archive campaign if one for popup has been added
      if (props.content?.campaigns?.length) {
        campaignManager.update()
      }
    })

    return {
      popup,
      inner,
      handleClose,
      close,
      emitSelection,
    }
  },
})
