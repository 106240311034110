


















































import type { Link } from '@/inc/types'
import { defineComponent, PropType } from '@vue/composition-api'

export interface Prices {
  label?: string
  items?: string[]
  details?: {
    price?: string
    dimensions?: string
    architect?: string
    worker?: string
  }
  htmltext?: string
  link?: Link
}

export default defineComponent({
  name: 'UiPrices',
  props: {
    content: {
      type: Object as PropType<Prices>,
      required: true,
    },
  },
  setup() {
    const detailsIcons = {
      price: 'euro',
      architect: 'pencil-ruler',
      worker: 'hammer',
      dimensions: 'ruler',
    }

    return {
      detailsIcons,
    }
  },
})
