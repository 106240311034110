












import type { FlexibleQuote, Cta } from '@/inc/types'
import type { CtaPreEncoded } from '@/components/cta/PreEncoded.vue'
import type { CtaExperience } from '@/components/cta/Experience.vue'
import { PropType, defineComponent } from '@vue/composition-api'

type CtaType = 'link' | 'text' | 'post-type' | 'experience' | 'jobs'
type CtaContent = FlexibleQuote | Cta | CtaPreEncoded | CtaExperience
type GCta = CtaContent & {
  type: CtaType
  blockType?: CtaType
}

export default defineComponent({
  name: 'GCtas',
  components: {},
  props: {
    ctas: {
      type: Array as PropType<GCta[]>,
      required: true,
      default: () => [],
    },
  },
  setup() {
    const components = {
      /* eslint-disable quote-props */
      link: () => import('@/components/flexible/Quote.vue'),
      text: () => import('@/components/cta/Text.vue'),
      'post-type': () => import('@/components/cta/PreEncoded.vue'),
      experience: () => import('@/components/cta/Experience.vue'),
      jobs: () => import('@/components/cta/Jobs.vue'),
      /* eslint-enable quote-props */
    }

    const getComponent = (cta: GCta) => {
      let component

      if (cta.blockType && components[cta.blockType]) {
        component = components[cta.blockType]
      } else if (components[cta.type]) {
        component = components[cta.type]
      }

      return component
    }

    return {
      getComponent,
    }
  },
})
