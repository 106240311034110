// "Borrowed" from https://github.com/greensock/GSAP/blob/master/src/Observer.js
// isTouch is 0 if no touch, 1 if ONLY touch, and 2 if it can accommodate touch but also other types like mouse/pointer.
const isTouch = () => {
  if (typeof window === 'undefined') {
    return 0
  }

  if (
    window.matchMedia &&
    window.matchMedia('(hover: none), (pointer: coarse)').matches
  ) {
    return 1
  }

  return 'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
    ? 2
    : 0
}

const isTouchOnly = () => isTouch() === 1

export { isTouch, isTouchOnly }
