/* eslint-disable @typescript-eslint/no-explicit-any */
import path from 'path'

export enum STORAGE {
  'NONE' = 'none',
  'LOCAL' = 'localStorage',
  'SESSION' = 'sessionStorage',
}

interface StorageOptions {
  type: STORAGE
}
export interface StorageUtils {
  readonly hasStorage: boolean
  setItem(k: string, v: any): void
  getItem(k: string, p?: boolean): any
  removeItem(k: string): void
}

const defaultOptions = {
  type: STORAGE.SESSION,
}

export const getOptions = (pluginRuntimeOptions: any) => {
  const opts = pluginRuntimeOptions ? pluginRuntimeOptions.storage || {} : {}

  return {
    ...defaultOptions,
    ...opts,
  } as StorageOptions
}

export default (api: any) => {
  api.addEnhanceFile({
    needCompile: false,
    client: path.resolve(__dirname, './storage-client.ts'),
    server: path.resolve(__dirname, './storage-server.ts'),
    clientModuleName: 'storage',
  })
}
