export let canSetMarketingCookies = false

// Check if the user has accepted marketing cookies
// Cookie parameter is this.$root.$cookie.get('OptanonConsent')
export const getCookieConsent = (cookie?: string) => {
  if (cookie) {
    // Cookie value is something like: `{key:'value',foo:'bar'}`
    // To read the properties, we convert it to a valid JSON string
    // https://stackoverflow.com/a/26291352
    const cookieJSON = cookie
      // wrap keys without quote with valid double quote
      .replace(/([$\w]+)\s*:/g, (_, $1) => `"${$1}":`)
      // replacing single quote wrapped ones to double quote
      .replace(/'([^']+)'/g, (_, $1) => `"${$1}"`)

    try {
      const { marketing } = JSON.parse(cookieJSON)

      canSetMarketingCookies = marketing || false
      console.log('[getCookieConsent] Based on marketing value:', marketing)
    } catch (e) {
      // cookieJSON is not valid JSON, meaning the value might have been corrupted
      // canSetMarketingCookies stays false
      canSetMarketingCookies = false
      console.log('[getCookieConsent] Value not valid')
    }
  } else {
    // If the cookie does not exist on the browser, track marketing cookies
    // (the user has not accepted nor refused the cookies
    // and has likely not encountered a cookie bar)
    canSetMarketingCookies = true
    console.log('[getCookieConsent] No CookieConsent cookie')
  }

  return canSetMarketingCookies
}
