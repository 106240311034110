import { GetterTree, Module, MutationTree } from 'vuex'
import { RootState } from '@/inc/store'
import { Material } from '@/inc/types'

interface MoodbuilderState {
  projectType: string
  projectTypeLabel: string
  moodTitle: string
  wallMaterial: Material | null
  floorMaterial: Material | null
  primaryMaterial: Material | null
  secondaryMaterial: Material | null
  tertiaryMaterial: Material | null
  accessoryMaterial: Material | null
  contact: {
    lastname: string
    name: string
    email: string
    zipcode: string
  }
}

const state: MoodbuilderState = {
  projectType: '',
  projectTypeLabel: '',
  moodTitle: '',
  wallMaterial: null,
  floorMaterial: null,
  primaryMaterial: null,
  secondaryMaterial: null,
  tertiaryMaterial: null,
  accessoryMaterial: null,
  contact: {
    lastname: '',
    name: '',
    email: '',
    zipcode: '',
  },
}

const getters: GetterTree<MoodbuilderState, RootState> = {
  projectType: (state: MoodbuilderState) => state.projectType,
  projectTypeLabel: (state: MoodbuilderState) => state.projectTypeLabel,
  moodTitle: (state: MoodbuilderState) => state.moodTitle,
  wallMaterial: (state: MoodbuilderState) => state.wallMaterial,
  floorMaterial: (state: MoodbuilderState) => state.floorMaterial,
  primaryMaterial: (state: MoodbuilderState) => state.primaryMaterial,
  secondaryMaterial: (state: MoodbuilderState) => state.secondaryMaterial,
  tertiaryMaterial: (state: MoodbuilderState) => state.tertiaryMaterial,
  accessoryMaterial: (state: MoodbuilderState) => state.accessoryMaterial,
  contact: (state: MoodbuilderState) => state.contact,
}

const mutations: MutationTree<MoodbuilderState> = {
  SET_PROJECT_TYPE(state, payload: { type: string; label: string } | null) {
    if (payload === null) {
      state.projectType = ''
      state.projectTypeLabel = ''
    } else {
      state.projectType = payload.type
      state.projectTypeLabel = payload.label
    }
  },
  SET_MOOD_TITLE(state, payload: string) {
    state.moodTitle = payload
  },
  SET_WALL_MAT(state, payload: Material) {
    state.wallMaterial = payload
  },
  SET_FLOOR_MAT(state, payload: Material) {
    state.floorMaterial = payload
  },
  SET_PRIMARY_MAT(state, payload: Material) {
    state.primaryMaterial = payload
  },
  SET_SECONDARY_MAT(state, payload: Material) {
    state.secondaryMaterial = payload
  },
  SET_TERTIARY_MAT(state, payload: Material) {
    state.tertiaryMaterial = payload
  },
  SET_ACCESSORY_MAT(state, payload: Material) {
    state.accessoryMaterial = payload
  },
  SET_CONTACT(state, payload) {
    state.contact = payload
  },
  SET_CONTACT_NAME(state, payload: string) {
    state.contact.name = payload
  },
}

const moodbuilder: Module<MoodbuilderState, RootState> = {
  state,
  getters,
  mutations,
  namespaced: true,
}

export { moodbuilder, MoodbuilderState }
