





































import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

import ChromeSocials from '@/components/chrome/Socials.vue'

export default defineComponent({
  name: 'ChromeFooterLinks',
  components: {
    ChromeSocials,
  },
  setup() {
    // Trigger cookiebot preferences popup
    const onCookiesClick = () => window.Cookiebot?.renew()

    return {
      ...useGetters(['chrome']),
      onCookiesClick,
    }
  },
})
