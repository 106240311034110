















































import { defineComponent, PropType } from '@vue/composition-api'
import { SelectOption } from '@/inc/types'
import { ValidationProvider, extend } from 'vee-validate'
import FormFeedback from '@/components/form/Feedback.vue'

import { required } from 'vee-validate/dist/rules.umd.js'

extend('required', {
  ...required,
  message: 'form-required',
})
import { createPopper } from '@popperjs/core'
import vSelect from 'vue-select'

export default defineComponent({
  name: 'FormSelect',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    options: {
      type: Array as PropType<SelectOption[]>,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      required: false,
    },
    autoPlacement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    FormFeedback,
    ValidationProvider,
    vSelect,
  },

  setup(props, ctx) {
    const selected = props.value
      ? props.options.find(o => [o.sfValue, o.value].includes(props.value))
      : undefined

    const selectedValue = item => {
      ctx.emit('input', item.value)
    }

    // Use popperjs to auto compute desired position of dropdown based on its size and its
    // position within viewport.
    // https://vue-select.org/guide/positioning.html#popper-js-integration
    const calculatePosition = (dropdownListEl, component, { width }) => {
      if (!props.autoPlacement) {
        return null
      }

      dropdownListEl.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownListEl, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('up', state.placement === 'top')
              dropdownListEl.classList.toggle('up', state.placement === 'top')
            },
          },
        ],
      })

      return () => popper.destroy()
    }

    return {
      selected,
      selectedValue,
      calculatePosition,
    }
  },
})
