












import {
  defineComponent,
  ref,
  onMounted,
  PropType,
  onUnmounted,
} from '@vue/composition-api'
import gsap, { TimelineLite } from 'gsap'

import UiLoader from '@/components/ui/Loader.vue'

export default defineComponent({
  name: 'UiMasonry',
  components: { UiLoader },
  props: {
    items: {
      type: Array as PropType<unknown[]>,
      default: () => [],
    },
    keyProp: {
      type: String,
      required: true,
    },
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)
    const isLoadingRef = ref(true)
    let masonry: any

    // Create promises to wait for images load
    const loadImages = () =>
      new Promise<void>(resolve => {
        const imgElements = rootElRef.value?.querySelectorAll('img')
        const imgPromises: Promise<void>[] = []

        if (imgElements && imgElements.length > 0) {
          imgElements?.forEach(img => {
            imgPromises.push(
              new Promise(resolve => {
                if (img.complete) {
                  // if img is cached, resolve immediately
                  resolve()
                } else {
                  // or wait for it to load
                  img.onload = () => {
                    resolve()
                  }
                }
              })
            )
          })

          Promise.all(imgPromises).then(() => resolve())
        } else {
          resolve()
        }
      })

    // Init masonry layout
    const initMasonry = async () => {
      const Masonry = await import(
        /* webpackChunkName: 'vendor-masonry-layout' */ 'masonry-layout'
      ).then(module => module.default)

      masonry = new Masonry(rootElRef.value, {
        itemSelector: '.masonry__item',
        columnWidth: '.masonry__sizer',
        percentPosition: true,
        transitionDuration: 0,
        horizontalOrder: true,
      })

      // Play reveal animation
      const tl = initTl()
      tl.play()
    }

    // Init timeline for reveal animation
    const initTl = (): TimelineLite => {
      const itemsEl = rootElRef.value?.querySelectorAll('.masonry__item')
      const loaderEl = rootElRef.value?.querySelectorAll('.masonry__loader')
      const tl = gsap.timeline({
        onComplete: () => {
          gsap.set([itemsEl, loaderEl], { clearProps: 'opacity' })
          isLoadingRef.value = false
        },
      })

      if (itemsEl && loaderEl) {
        tl.set(rootElRef.value, { opacity: 1 })
          .to(loaderEl, { opacity: 0, duration: 0.5 })
          .fromTo(
            itemsEl,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, stagger: 0.05 },
            '<+=0.3'
          )
      }

      return tl
    }

    // When new items are added to the grid, refresh the layout to display everything correctly
    // Only new items should be handled
    const refreshLayout = async () => {
      if (!masonry || !rootElRef.value) {
        return
      }

      try {
        // Wait for all images to load, so we don't have to refresh the layout multiple times
        await loadImages()

        // Get news items (since they're not in the masonry, they don't have the style attr)
        const itemsEl = rootElRef.value.querySelectorAll(
          '.masonry__item:not([style])'
        )
        const tl = gsap.timeline()

        tl.set(itemsEl, { opacity: 0, y: 20 })
          .add(() => {
            masonry.reloadItems()
          })
          .add(() => {
            // Safari needs timeout otherwise the layout is not performed
            // #332 was fixed but not on Safari
            masonry.layout()
          }, '+=0.5')
          .to(itemsEl, { opacity: 1, y: 0, stagger: 0.05 })
      } catch (e) {
        console.error('Error loading images', e)
      }
    }

    onMounted(async () => {
      try {
        // Wait for all imgs to be loaded before creating gallery
        await loadImages()
        initMasonry()
      } catch (e) {
        console.error('Error loading images', e)
      }
    })

    onUnmounted(() => {
      // Destroy masonry instance
      masonry?.destroy()
    })

    return {
      rootElRef,
      isLoadingRef,
      masonry,
      refreshLayout,
    }
  },
})
